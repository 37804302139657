<template>
	<div class="form-group">
		<label v-if="label !== undefined && label !== null">{{ label }}:</label>
		<codemirror v-model="finalCode" :options="options"></codemirror>
		<p class="small" v-if="explanation !== undefined">{{ explanation }}</p>
	</div>
</template>

<script>
import {codemirror} from 'vue-codemirror';
import 'codemirror/mode/htmlembedded/htmlembedded'
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/neo.css';

export default {
    name: "CodeField",
    props: ['code', 'type', 'label', 'explanation', 'editable'],
    components: {codemirror},
    data() {
        return {
            options: {
                tabSize: 4,
                mode: this.getType(this.type),
                line: true,
                lineNumbers: true,
                theme: 'neo',
            },
            finalCode: this.code,
        }
    },
    methods: {
        getType(type) {
            if (type === "HTML") {
                return "text/html";
            } else if (type === "JavaScript") {
                return "htmlmixed";
            }
        }
    },
    watch: {
        type: function (newType) {
            if (newType === "HTML") {
                this.options.mode = "text/html";
            } else if (newType === "JavaScript") {
                this.options.mode = "text/javascript";
            }
        },
        code: function (newValue) {
            this.finalCode = newValue;
        },
    },
}
</script>

<style>
.CodeMirror {
    height: auto !important;
    padding: 10px;
    border-radius: 0.75rem;
    border: 1px solid #eee;
}
</style>

<style lang="scss" scoped>
@import "src/scss/variables";

.form-group {
	label {
		color: $dark;
		font-size: 1rem;
		font-weight: 600;
		margin-bottom: 3px;
		display: block;
	}

	.form-control {
		padding: 15px 10px;
		height: auto;
		border: 1px solid $border-darker-grey;
		background: #fff;

		&:focus {
			box-shadow: none;
			border-color: $primary;
		}
	}

	p {
		margin-top: 5px;

		&.small {
			color: $grey-text;
		}
	}
}
</style>