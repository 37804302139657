<template>
    <div class="form-wrapper">

        <div class="alert alert-danger p-0" v-if="error">{{ error }}</div>

        <form action="#" @submit.prevent="saveForm" :class="{'new-project-form': true}" style="min-height: 150px;">

            <!-- Form Fields -->
            <div class="row" v-if="projectId && loaded">
                <div class="col-lg-6">

                    <div class="fields-wrapper">
                        <h1>Form Details</h1>

                        <!-- Name & Color -->
                        <div class="form-group form-group-color">
                            <label>Name</label>
                            <input type="text" class="form-control" placeholder="Enter your form name" v-model="name">
                            <ColorPicker v-model="color" ref="picker"></ColorPicker>
                        </div>

                        <!-- Form Type -->
                        <div class="form-group">
                            <label>Form Type</label>
                            <select class="form-control" v-model="type">
                                <option value="2">Form Builder</option>
                                <option value="1">Custom Code</option>
                            </select>
                            <p class="small">Use the <strong>Form Builder</strong> for an easy drag-and-drop experience, or choose the <strong>Custom Code</strong> option if you prefer to code your forms manually.</p>
                        </div>

                        <!-- Recipients -->
                        <RecipientsField v-model="recipients" v-if="loaded"/>
                    </div>

                    <div class="fields-wrapper">
                        <h1>Spam Protection</h1>

                        <!-- ReCaptcha v3 -->
                        <div class="form-group">
                            <label for="recaptcha">reCAPTCHA Secret Key</label>
                            <input type="text" class="form-control" id="recaptcha" placeholder="Enter reCAPTCHA Secret Key" v-model="recaptchaPrivateKey">
                            <p class="small">Pass the reCAPTCHA generated token as <strong>g-recaptcha-response</strong> on your form.</p>
                        </div>

                        <!-- HoneyPot Fields -->
                        <HoneyPotFields v-model="honeypotFields" v-if="loaded"/>
                    </div>
                </div>
            </div>

            <button type="submit" :class="{'btn btn-saving btn-primary': true, 'disabled': !updated}" :disabled="saving || !projectId || !loaded || !updated" v-if="projectId && loaded">
                <span class="spinner-border" v-if="saving || !projectId || !loaded"></span>
                <span :class="{'opacity-0': saving || !projectId || !loaded}" v-if="updated">Save Changes</span>
                <img :class="{'opacity-0': saving || !projectId || !loaded, 'ml-n3': true}" v-if="!updated" src="@/assets/icons/check.svg" alt="">
                <span :class="{'opacity-0': saving || !projectId || !loaded}" v-if="!updated">Up to date</span>
            </button>

        </form>

        <div class="delete-form-box" v-if="loaded">
            <div class="row">
                <div class="col-lg-6">
                    <div class="fields-wrapper">
                        <h1>Delete Form</h1>
                        <p>Delete your form and submissions permanently. Once deleted, you cannot restore your form or your submissions on this form. You won't be able to accept any new submissions from any forms that you have connected with this Form.</p>

                        <!-- Delete form -->
                        <a href="#" @click.prevent="deleteForm" :class="{'btn btn-danger': true, 'loading': actions.deleting}">
                            <img src="@/assets/icons/trash.svg" alt="Trash" :class="{'opacity-0': actions.deleting}">
                            <span :class="{'opacity-0': actions.deleting}">Delete Form</span>
                            <span v-show="actions.deleting" class="spinner-border"></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ColorPicker from "@/components/widgets/ColorPicker";
import repository from "@/repository/repository";
import RecipientsField from "@/components/widgets/RecipientsField.vue";
import HoneyPotFields from "@/components/widgets/HoneyPotFields.vue";

export default {
    name: "EditForm",
    components: {HoneyPotFields, RecipientsField, ColorPicker},
    props: ['formId'],
    data() {
        return {
            name: "",
            type: null,
            recaptchaPrivateKey: null,
            recipients: [],
            honeypotFields: [],
            color: null,
            error: null,
            loaded: false,
            saving: false,
            updated: false,
            actions: {
                deleting: false,
            },
        }
    },
    created() {
        this.loadFormDetails();

        let propertiesToWatch = Object.keys(this.$data).filter(prop => ['loaded', 'saving', 'updated'].indexOf(prop) < 0);
        propertiesToWatch.forEach(prop => {
            this.$watch(prop, () => {
                this.updated = true;
            }, { deep: true });
        });
    },
    methods: {
        loadFormDetails() {
            if(!this.projectId) return;
            repository.get("/projects/" + this.projectId + "/forms/" + this.formId)
                .then(response => {
                    this.name = response.data.name;
                    this.type = response.data.type;
                    this.recipients = response.data.recipients;
                    this.honeypotFields = response.data.honeypot_fields;
                    this.color = response.data.color_id;
                    this.recaptchaPrivateKey = response.data.recaptcha_private;
                    this.loaded = true;
                    this.$nextTick(() => {
                        this.updated = false;
                    });
                })
        },
        saveForm() {
            if(!this.projectId) return;
            this.startSaving();
            this.setError(null);
            if (!this.name || this.name.trim().length < 3) {
                this.setError("The form name is required, and must have at least 3 characters.");
                this.endSaving();
                return;
            }
            // If everything passes, save the project
            repository.put("/projects/" + this.projectId + "/forms/" + this.formId, {
                name: this.name,
                type: this.type,
                recipients: this.recipients,
                recaptcha_private: this.recaptchaPrivateKey,
                honeypot_fields: this.honeypotFields,
                color_id: this.color,
            })
                .then(() => {
                    // Refresh the forms
                    this.$root.$emit("reloadForms");
                    this.$root.$emit("reloadCurrentForm");

                    // Redirect back to home screen, or refresh stats if already there
                    // this.$router.push("/forms/" + this.formId);
                    //
                    // // Reset fields
                    // this.name = "";
                    // this.recipients = "";
                    // this.$refs.picker.randomValue();
                    this.updated = false;
                    this.endSaving();
                })
                .catch(error => {
                    if (error.response.data.message) {
                        this.setError(error.response.data.message);
                    } else {
                        this.setError("An error happened while updating your form.");
                    }
                    this.endSaving();
                })
        },
        setError(error) {
            this.error = error;
        },
        startSaving() {
            this.saving = true;
        },
        endSaving() {
            this.saving = false;
        },
        deleteForm() {
            if (!this.projectId) return;
            if (!this.formId) return;
            if (this.actions.deleting) return;
            this.$confirm({
                title: "Delete " + this.name + "?",
                message: "Please note that this action will permanently remove the "+ this.name +" and all linked submissions. This action cannot be undone.",
                button: {
                    yes: "Yes, continue",
                    no: "No, cancel"
                },
                callback: confirm => {
                    if (confirm) {
                        this.actions.deleting = true;
                        repository.delete("/projects/" + this.projectId + "/forms/" + this.formId)
                            .then(() => {
                                // Refresh the forms
                                this.$root.$emit("reloadForms");

                                // Redirect to home
                                this.$router.replace("/");
                                this.actions.deleting = false;
                            })
                            .catch(error => {
                                console.log(error);
                                this.actions.deleting = false;
                            })
                    }
                }
            })
        },
    },
    computed: {
        projectId() {
            return this.$store.getters.currentProject.hashId;
        },
    },
    watch: {
        projectId: function (newValue) {
            if(newValue !== null) this.loadFormDetails();
        },
    },
}
</script>

<style scoped lang="scss">
@import "../../scss/variables";

.form-wrapper {
    .new-project-form {
        .loading {
            background: none;
        }
    }
}

.delete-form-box {
    @include smartphone {
        padding-bottom: 5rem;
    }

    .btn {
        display: inline-flex;
        align-items: center;

        img {
            width: 1.25rem;
            height: 1.25rem;
            margin: -0.25rem 0.25rem -0.25rem -0.25rem;
            filter: brightness(0) invert(1);
        }
    }
}

.fields-wrapper {
    padding: 1.5rem;
    background: $white;
    border-radius: $box-border-radius;
    box-shadow: rgba($dark, 0.1) 0 1px 2px;
    margin-bottom: 1rem;

    h1 {
        margin-bottom: 1.5rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid rgba($dark, 0.1);
    }
}

.form-group.form-group-color {
    position: relative;

    .color-picker {
        position: absolute;
        bottom: 1px;
        right: 1px;
    }
}

.btn.btn-saving {
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    width: 20rem;
    z-index: 9;
    background: #84c318;
    border-color: #84c318;
    animation: 1.25s linear infinite blinking-effect;

    &:hover,
    &:focus {
        background: lighten(#84c318, 2.5%);
        border-color: lighten(#84c318, 2.5%);
    }

    &:active {
        background: lighten(#84c318, 5%) !important;
        border-color: lighten(#84c318, 5%) !important;
        transform: scale(0.9875);
    }

    @include smartphone {
        bottom: 5rem;
        left: 1.5rem;
        right: 1.5rem;
        width: auto;
    }

    @include small-desktop-and-above {
        top: 6.65rem;
        bottom: auto;
        right: 1.5rem;
        padding: 1.5rem;
        width: 22rem;

        &[disabled] {
            opacity: 0.5 !important;
        }
    }

    &[disabled] {
        filter: grayscale(1);
        opacity: 1;
        animation: none;
        @extend .disable-clicks;
        @extend .disable-selection;
    }
}
</style>