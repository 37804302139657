<template>
	<div class="realtime-notifications-wrapper">
        <transition-group name="list" tag="div" class="list-wrap">
            <router-link
                v-for="(submission) in newSubmissions"
                :class="{'notification-item': true}"
                :to="'/submissions/' + submission.id"
                :key="submission.id"
                @click.native="markSubmissionAsSeen(submission)"
                @mouseleave.native="markSubmissionAsSeen(submission, 0.35)"
            >
                <div class="notification-form-color" :style="{'backgroundColor': submission.form_color}"></div>
                <div class="notification-details">
                    <h6>New Submission</h6>
                    <strong>{{ submission.name }}</strong>
                    <span>{{ submission.email }}</span>
                </div>
            </router-link>
        </transition-group>
    </div>
</template>

<script>
import Pusher from "pusher-js";

export default {
    name: "RealtimeNotifications",
    data() {
        return {
            currentProjectId: null,
            pusher: null,
            submissions: {
                items: [],
            },
            automaticallyHiddenSubmissions: 0,
        }
    },
    created() {
        this.startPusher();
    },
    methods: {
        startPusher() {
            // Enable pusher logging - don't include this in production
            // Pusher.logToConsole = true;

            // Start Pusher
            this.pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
                cluster: process.env.VUE_APP_PUSHER_CLUSTER
            });

            // Start listening for submissions on project
            this.listenSubmissionForProject(this.$store.getters.currentProject.hashId);
        },
        listenSubmissionForProject(projectId) {
            if(!projectId) return;

            let app = this;

            // Unsubscribe from previous channel
            if(app.currentProjectId) {
                app.pusher.unsubscribe('formwise-'+ process.env.VUE_APP_PUSHER_ENVIRONMENT +'-' + app.currentProjectId);
                app.currentProjectId = null;
            }

            // Subscribe to new channel
            let channel = app.pusher.subscribe('formwise-'+ process.env.VUE_APP_PUSHER_ENVIRONMENT +'-' + projectId);
            app.currentProjectId = projectId;

            // On New Project Submission
            channel.bind('new-submission', function(data) {
                // Save submission in notifications
                let submission = {
                    ...data,
                    seen: false,
                };
                app.submissions.items.unshift(submission);

                // Play audio effect
                app.playNewSubmissionSound();

                // Reload stats & forms
                // app.$root.$emit('reloadStats');
                app.$root.$emit('reloadFormSubmissions');
                // app.$root.$emit('reloadForms');
            });
        },
        playNewSubmissionSound() {
            let audio = new Audio(require('./../../assets/sounds/new-submission.mp3'));
            audio.play().catch(() => {
                console.log("The user must interact with the page in order to play sounds.");
            });
        },
        markSubmissionAsSeen(submission, secondsDelay = 0) {
            if(submission.seen) return;
            let app = this;
            setTimeout(function () {
                app.submissions.items.filter(_submission => _submission.id === submission.id).forEach(_submission => {
                    _submission.seen = true;
                });
            }, secondsDelay * 1000);
        },
        updatePageFavicon() {
            let faviconElement = document.querySelector("link[rel~='icon']");
            if(this.totalNewSubmissions > 0) {
                faviconElement.href = "/favicon-notification.png";
            } else {
                faviconElement.href = "/favicon.png";
            }
        },
    },
    computed: {
        newSubmissions() {
            return this.submissions.items.filter(_s => !_s.seen);
        },
        totalNewSubmissions() {
            return this.newSubmissions.length;
        },
    },
    watch: {
        '$store.getters.currentProject.hashId': function (newProjectId) {
            this.listenSubmissionForProject(newProjectId);
        },
        totalNewSubmissions: function () {
            this.updatePageFavicon();
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../../src/scss/variables";

.realtime-notifications-wrapper {
    position: fixed;
    top: 1rem;
    right: 1rem;
    width: 20rem;
    z-index: 101;

    .notification-item {
        background: rgba($dark, 0.85);
        backdrop-filter: blur(5px);
        box-shadow: rgba($dark, 0.15) 0 0.25rem 0.5rem;
        border-radius: 1rem;
        padding: 1rem;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: rgba($white, 0.5);
        transition: 0.25s ease all;

        &:hover {
            background: $dark;
        }

        & + .notification-item {
            margin-top: 0.5rem;
        }

        .notification-form-color {
            width: 2rem;
            height: 2rem;
            flex-shrink: 0;
            border-radius: 0.75rem;
            border: 3px solid $white;
        }

        .notification-details {
            padding-left: 0.5rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            h6 {
                font-size: 0.8rem;
                color: $white;
                font-weight: 600;
                margin: 0;
            }

            strong {
                color: $white;
            }

            span {
                color: rgba($white, 0.5);
                font-size: 0.9rem;
                margin-left: 0.5rem;
            }
        }
    }
}


.list-wrap {
    position: relative;
    transition: 0.5s ease all;
}

.list-item {
}

.list-enter, .list-leave-to {
    opacity: 0;
    transform: translateX(20px);
}

.list-leave-active {
    position: absolute;
    width: 100%;
}
</style>