<template>
    <form class="element-editor" @submit.prevent="saveChanges(true)">
        <div class="form-field">
            <label for="text" class="form-label">Text</label>
            <input type="text" v-model="text" class="form-control" name="text" id="text" @input="saveChanges">
        </div>
        <div class="form-field">
            <label for="label" class="form-label">Type</label>
            <div class="field-radio-group" role="group" aria-label="Heading Type">
                <div class="field-radio-item">
                    <input type="radio" class="btn-check" name="type" id="type-h1" autocomplete="off" v-model="type" value="h1" @change="saveChanges">
                    <label class="btn" for="type-h1">H1</label>
                </div>
                <div class="field-radio-item">
                    <input type="radio" class="btn-check" name="type" id="type-h2" autocomplete="off" v-model="type" value="h2" @change="saveChanges">
                    <label class="btn" for="type-h2">H2</label>
                </div>
                <div class="field-radio-item">
                    <input type="radio" class="btn-check" name="type" id="type-h3" autocomplete="off" v-model="type" value="h3" @change="saveChanges">
                    <label class="btn" for="type-h3">H3</label>
                </div>
                <div class="field-radio-item">
                    <input type="radio" class="btn-check" name="type" id="type-h4" autocomplete="off" v-model="type" value="h4" @change="saveChanges">
                    <label class="btn" for="type-h4">H4</label>
                </div>
                <div class="field-radio-item">
                    <input type="radio" class="btn-check" name="type" id="type-h5" autocomplete="off" v-model="type" value="h5" @change="saveChanges">
                    <label class="btn" for="type-h5">H5</label>
                </div>
                <div class="field-radio-item">
                    <input type="radio" class="btn-check" name="type" id="type-h6" autocomplete="off" v-model="type" value="h6" @change="saveChanges">
                    <label class="btn" for="type-h6">H6</label>
                </div>
            </div>
        </div>
        <button type="submit" class="btn btn-primary">Confirm</button>
    </form>
</template>

<script>
export default {
    name: "HeadingElement",
    props: [
        'element',
        'updateElement',
    ],
    data() {
        return {
            type: '',
            text: '',
        }
    },
    created() {
        this.initiateFields(this.element);
    },
    methods: {
        initiateFields(data) {
            if(!data) return;
            this.type = data.type;
            this.text = data.text;
        },
        saveChanges(closeSidebar = false) {
            this.updateElement({
                _id: this.element._id,
                element: this.element.element,
                type: this.type,
                text: this.text,
            }, closeSidebar);
        },
    },
    watch: {
        element(newData) {
            this.initiateFields(newData);
        },
    },
}
</script>

<style scoped lang="scss">
@import "../../../../scss/variables";

.element-editor {
    .field-radio-group {
        display: flex;
        align-items: center;

        .field-radio-item {
            display: block;
            margin-right: 0.25rem;

            input {
                display: none;

                &:checked ~ .btn {
                    background: $primary;
                    color: $white;
                }
            }

            .btn {
                display: block;
                margin: 0;
                width: auto;
                height: auto;
                font-size: 0.9rem;
                font-weight: 500;
                color: $dark;
                background: $background-grey;
                border-radius: $box-border-radius;
                @extend .animated;

                &:hover {
                    background: rgba($primary, 0.2);
                    color: $primary;
                }
            }
        }
    }
}
</style>