<template>
	<router-link to="/purchase-submissions" :class="{'submissions-limit': true, 'loading': !limits.loaded, 'danger': usedPercentage > 90}">
        <div class="submissions-limit-wrapper">
            <div class="progress">
                <div :class="{'progress-bar': true}" :style="{width: this.usedPercentage + '%'}"></div>
            </div>
            <span>{{ usedSubmissions }} </span>
            <strong>{{ limits.purchasedSubmissions }}</strong>
        </div>

        <div class="explanation-box" v-if="usedPercentage <= 90">
            <strong>Available Submissions</strong>
            <span>You have 150 submission slots available on the free plan. Need more? Upgrade to get additional slots!</span>
        </div>

        <div class="explanation-box" v-if="usedPercentage > 90 && usedPercentage < 100">
            <strong>Missing Storage</strong>
            <span>You have only {{ limits.availableSubmissions }} submission slots left. Need more? Upgrade to get additional slots!</span>
        </div>

        <div class="explanation-box" v-if="usedPercentage >= 100">
            <strong>Storage Full</strong>
            <span>Your submissions storage is full. Please purchase additional submissions slots to continue receiving notifications!</span>
        </div>
	</router-link>
</template>

<script>
export default {
    name: "SubmissionsLimit",
    computed: {
        limits() {
            return this.$store.getters.limits;
        },
        usedSubmissions() {
            return this.limits.purchasedSubmissions - this.limits.availableSubmissions;
        },
        usedPercentage() {
            return (100 / this.limits.purchasedSubmissions) * this.usedSubmissions;
        },
    },
}
</script>

<style lang="scss">
@import "src/scss/variables";

.submissions-limit {
    position: relative;
    margin-top: auto;
    z-index: 4;

    @include smartphone {
        display: none;
    }

    &:hover {
        text-decoration: none;
    }

    .submissions-limit-wrapper {
        position: relative;
        color: $dark;
        font-weight: 600;
        border: 1px solid $border-grey;
        border-radius: $box-border-radius * 2;
        padding: 0.5rem 0.75rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        z-index: 4;
        @extend .animated;
        @extend .disable-selection;

        &:hover {
            border-color: $border-dark-grey;
        }

        &:active {
            border-color: transparent;
            background-color: rgba($dark, 0.05);
        }

        .progress {
            flex-grow: 1;
            margin-right: 1rem;
            border-radius: $box-border-radius * 2;

            .progress-bar {
                background-color: $primary;
                border-radius: $box-border-radius * 2;

                &.danger {
                    background-color: $danger;
                }
            }
        }

        > strong {
            font-size: 0.9rem;
            font-weight: 600;
            color: $primary;

            &:before {
                content: '/';
                margin: 0 0.25rem;
                color: $dark;
                opacity: 0.25;
            }
        }

        > span {
            font-size: 0.9rem;
            font-weight: 600;
            color: $dark;
        }
    }

    .explanation-box {
        position: absolute;
        bottom: calc(100% + 0.5rem);
        left: 0;
        width: 100%;
        height: auto;
        border-radius: $box-border-radius;
        background: $grey-background;
        color: $white;
        padding: 1rem;
        opacity: 0;
        visibility: hidden;
        transform-origin: center;
        transform: translateY(1rem);
        z-index: -1;
        @extend .animated;

        strong {
            display: block;
            font-size: 1rem;
            margin-bottom: 0.25rem;
        }

        span {
            display: block;
            font-size: 0.8rem;
            color: rgba($white, 1);
            font-weight: 400;
            margin: 0;
        }
    }

    &.loading {
        opacity: 0;
    }

    &.danger {
        padding-top: 7.5rem;

        .progress {
            .progress-bar {
                background-color: $danger;
            }
        }

        > span {
            color: $danger;
        }

        .explanation-box {
            background-color: $danger;
            opacity: 1;
            visibility: visible;
            transform: none;
            bottom: 3rem;
        }
    }

    &:hover {
        .explanation-box {
            opacity: 1;
            visibility: visible;
            transform: none;
        }
    }
}
</style>