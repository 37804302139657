import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        projects: {
            items: [],
            loaded: false,
        },
        forms: {
            items: [],
            loaded: false,
        },
        tags: {
            items: [],
            loaded: false,
        },
        project: {
            forceUpdate: false,
        },
        limits: {
            purchasedSubmissions: 0,
            availableSubmissions: 0,
            loaded: false,
        },
        user: {
            token: localStorage.getItem("token"),
            name: null,
            email: null,
            avatar: {
                credentials: null,
                color: null,
                text: null,
            },
            customerId: null,
            loaded: false,
        },
        currentProject: {
            hashId: null,
            name: null,
            loaded: false,
        },
        dropdown: null,
    },
    getters: {
        projects(state) {
            return state.projects;
        },
        forms(state) {
            return state.forms;
        },
        tags(state) {
            return state.tags;
        },
        user(state) {
            return state.user;
        },
        limits(state) {
            return state.limits;
        },
        currentProject(state) {
            return state.currentProject;
        },
        token(state) {
            return state.user.token;
        },
        dropdown(state) {
            return state.dropdown;
        },
        projectUpdate(state) {
            return state.project.forceUpdate;
        }
    },
    mutations: {
        updateProjects(state, projectsPayload) {
            state.projects.items = projectsPayload;
            state.projects.loaded = true;
        },
        updateForms(state, formsPayload) {
            state.forms.items = formsPayload;
            state.forms.loaded = true;
        },
        updateProject(state, payload) {
            state.project.forceUpdate = payload;
        },
        loadingProjects(state) {
            state.projects.items = [];
            state.projects.loaded = false;
        },
        loadingForms(state) {
            state.forms.items = [];
            state.forms.loaded = false;
        },
        updateTags(state, tagsPayload) {
            state.tags.items = tagsPayload;
            state.tags.loaded = true;
        },
        loadingTags(state) {
            state.tags.items = [];
            state.tags.loaded = false;
        },
        deleteTag(state, tag) {
            let index = state.tags.items.findIndex(t => t.hashId === tag.hashId);
            state.tags.items.splice(index, 1);
        },
        updateTag(state, tag) {
            let index = state.tags.items.findIndex(t => t.hashId === tag.hashId);
            state.tags.items[index].name = tag.name;
        },
        addTag(state, tag) {
            state.tags.items.push(tag);
        },
        updateUser(state, userPayload) {
            state.user.name = userPayload.name;
            state.user.email = userPayload.email;
            state.user.avatar = userPayload.avatar;
            state.user.customerId = userPayload.customer_id;
            state.user.loaded = true;
        },
        updateLimits(state, limitsPayload) {
            state.limits.purchasedSubmissions = limitsPayload.purchasedSubmissions;
            state.limits.availableSubmissions = limitsPayload.availableSubmissions;
            state.limits.loaded = true;
        },
        updateCurrentProject(state, projectPayload) {
            state.currentProject.hashId = projectPayload.hashId;
            state.currentProject.name = projectPayload.name;
            state.currentProject.loaded = true;
        },
        loadingUser(state) {
            state.user.loaded = false;
        },
        updateUserToken(state, token) {
            state.user.token = token;
            localStorage.setItem("token", token);
        },
        logoutUser(state) {
            localStorage.removeItem("token");
            state.user.token = null;
            state.user.name = null;
            state.user.email = null;
            state.user.avatar = null;
            state.projects.loaded = false;
            state.forms.loaded = false;
            state.currentProject.loaded = false;
        },
        saveDropdown(state, dropdown) {
            if (state.dropdown !== null) {
                state.dropdown.hideDropdown();
            }
            state.dropdown = dropdown;
        },
        clearDropdown(state) {
            state.dropdown = null;
        }
    },
    actions: {},
    modules: {}
})