<template>
	<div class="daily-chart">
        <div class="row align-items-center mb-3">
            <div class="col-12">
                <ExplanationTitle class="mb-0" heading="Submissions in the last 14 days" explanation="Here's an overview of the submissions received for this project in the last 14 days."></ExplanationTitle>
            </div>
        </div>
        <div :class="{'chart-box': true, 'loading': !loaded}">
            <VueApexCharts type="area" :height="height" :options="areaChartOptions" :series="areaSeries"></VueApexCharts>
        </div>
	</div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import ExplanationTitle from "../../components/widgets/ExplanationTitle";

export default {
    name: "ProjectDailyChart",
    components: {ExplanationTitle, VueApexCharts,},
    props: ['submissions', 'daily', 'loaded'],
    methods: {
        prepareDaily(submissions) {
            if (submissions.length === 0) return [];
            return Object.keys(submissions).map(key => submissions[key]);
        },
    },
    computed: {
        projectId() {
            return this.$store.getters.currentProject.hashId;
        },

        // Line Chart
        areaSeries() {
            if(!this.loaded) return [];
            let series = [];
            this.daily.forEach(form => {
                series.push({
                    name: form.name,
                    color: form.color,
                    data: this.prepareDaily(form.submissions),
                })
            });
            return series;
        },
        areaChartOptions() {
            return {
                chart: {
                    height: this.height,
                    type: 'line',
                    stacked: false,
                    toolbar: {
                        show: false,
                    },
                },
                colors: [
                    "#031feb",
                ],
                fill: {
                    type: 'solid',
                },
                stroke: {
                    curve: 'smooth',
                    width: 3,
                },
                grid: {
                    borderColor: "#eee",
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy',
                    },
                    marker: {
                        show: true,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    labels: {
                        colors: "#aaa",
                    }
                },
                xaxis: {
                    type: 'date',
                    categories: this.categories,
                    labels: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: {
                    max: this.maximumChartValue,
                    labels: {
                        formatter: function (value) {
                            return value.toFixed(0);
                        },
                    },
                },
            };
        },
        height() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return 200
            } else {
                return 250
            }
        },
        categories() {
            if (this.daily.length === 0) return [];
            return Object.keys(this.daily[0].submissions);
        },
        maximumChartValue() {
            let max = 1;
            this.daily.forEach(form => {
                Object.values(form.submissions).forEach(totalSubmissions => {
                    if(totalSubmissions > max) {
                        max = totalSubmissions;
                    }
                })
            });
            return max + 1;
        },
    },
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.chart-box {
    display: block;
    padding: 20px;
    background: $white;
    border-radius: $box-border-radius;
    box-shadow: rgba($dark, 0.1) 0 1px 2px;
    position: relative;
    text-decoration: none;
    margin-bottom: 30px;
    overflow: hidden;
    @extend .animated;

    @include small-desktop {
        padding: 15px;
    }

    @include tablet {
        padding: 15px;
    }

    @include smartphone {
        padding: 0;
        box-shadow: none !important;
    }

    &:hover {
        box-shadow: $box-shadow-color 0 3px 6px;
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $white;
        z-index: 1;
        content: ' ';
        opacity: 0;
        visibility: hidden;
        transition: 0.15s ease all;
    }

    &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 25px;
        height: 25px;
        border: 2px solid $border-darker-grey;
        border-right-color: transparent;
        content: ' ';
        z-index: 2;
        border-radius: 50%;
        margin: auto;
        animation: spinner-border 0.75s linear infinite;
        opacity: 0;
        visibility: hidden;
        transition: 0.15s ease all;
    }

    &.loading {
        &:before,
        &:after {
            opacity: 1;
            visibility: visible;
            transition: none;
        }
    }

    img.icon {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 25px;
        width: 40px;
        height: 40px;
        user-select: none;
        pointer-events: none;

        @include small-desktop {
            left: 15px;
            width: 30px;
            height: 30px;
        }

        @include tablet {
            left: 15px;
            width: 25px;
            height: 25px;
        }

        @include smartphone {
            position: relative;
            top: 0;
            left: 0;
            margin: 0;
            width: 25px;
            height: 25px;
        }
    }

    strong {
        font-weight: 700;
        color: $dark;
        display: block;
        font-size: 2rem;
        margin-bottom: -5px;

        @include smartphone {
            font-size: 1.5rem;
        }
    }

    span {
        font-weight: 400;
        color: $dark;
        display: block;
        margin: 0;

        @include smartphone {
            font-size: 0.8rem;
        }
    }

    @include tablet {
        padding: 10px 10px 10px 50px;
    }
}

.line-chart-area {
    width: 100%;
}
</style>

<style lang="scss">
@import "src/scss/variables";
.apexcharts-tooltip.apexcharts-theme-light {
    padding: 10px !important;
    border-radius: 1rem !important;
    box-shadow: rgba($dark, 0.1) 0 0.5rem 1.5rem !important;
    border: none !important;

	.apexcharts-tooltip-title {
		background: none !important;
		border: none !important;
		padding: 10px 10px 5px 10px !important;
		margin: 0 !important;
		font-weight: 600 !important;
		color: $primary !important;
        font-size: 1rem !important;
		font-family: 'Inter', sans-serif !important;
	}

	.apexcharts-tooltip-series-group {
		padding: 0 10px 5px 10px;
		display: flex !important;
        align-items: center;

		.apexcharts-tooltip-marker {
            display: block;
            border-radius: 0.25rem;
            margin-right: 5px;
		}

		.apexcharts-tooltip-text {
			display: block;
			width: 100%;
		}

		.apexcharts-tooltip-y-group {
			padding: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

			span {
				color: $dark;
				font-size: 0.9rem;
				font-family: 'Inter', sans-serif;
				display: inline-block;
				margin-right: 5px;

                &.apexcharts-tooltip-text-label {
                    color: rgba($dark, 0.5);
                }

                &.apexcharts-tooltip-text-value {
                    font-weight: bold;
                }
			}
		}
	}
}
</style>