<template>
    <div :class="{'form-field-row': true, 'dragging': dragging}">
        <!-- Columns -->
        <slot></slot>

        <!-- Row Actions -->
        <div class="row-actions row-actions-start">
            <button type="button" @click.prevent class="row-action-button action-move" title="Delete">
                <img src="@/assets/icons/dots.svg" alt="">
            </button>
        </div>
        <div class="row-actions row-actions-end">
            <button type="button" @click.prevent="deleteRow" class="row-action-button action-delete" title="Delete">
                <img src="@/assets/icons/trash.svg" alt="">
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormRow",
    props: ['row', 'id', 'dragging', 'delete'],
    methods: {
        deleteRow() {
            this.delete(this.id);
        },
    },
}
</script>

<style scoped lang="scss">
@import "../../../scss/variables";

.form-field-row {
    background: $white;
    border-radius: $box-border-radius;
    display: flex;
    padding: 0.7rem 0.35rem 0.7rem 1.45rem;
    width: calc(100% + 2.5rem + 2px);
    margin: 0 calc(-0.7rem - 1px) 0 calc(-1.8rem - 1px);
    border: 1px solid transparent;
    position: relative;
    @extend .animated;

    &:not(.dragging):hover {
        //border: 1px solid $border-dark-grey;
        background: $background-grey;

        .row-actions {
            opacity: 1;
            visibility: visible;
        }
    }

    .row-actions {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        width: auto;
        height: 2rem;
        right: -1rem;
        z-index: 3;
        opacity: 0;
        visibility: hidden;
        @extend .animated;
        @extend .disable-selection;

        &.row-actions-end {
            right: -1rem;
        }

        &.row-actions-start {
            right: auto;
            left: 0;
            height: 100%;
        }

        .row-action-button {
            width: 2rem;
            height: 2rem;
            line-height: 1rem;
            text-align: center;
            display: block;
            background: $primary;
            padding: 0;
            border: none;
            outline: none;
            box-shadow: none;
            border-radius: 0.75rem;
            @extend .animated;

            &.action-move {
                cursor: move;
                width: 1rem;
                height: 100%;
                border-radius: $box-border-radius;
                background: rgba($dark, 0.125);

                img {
                    filter: brightness(0);
                    opacity: 0.5;
                }

                &:hover {
                    background: $primary;
                    box-shadow: rgba($primary, 0.25) 0 0.25rem 0.5rem;

                    img {
                        filter: brightness(0) invert(1);
                        opacity: 1;
                    }
                }

                &:active {
                    background: $primary;
                    box-shadow: rgba($primary, 0.25) 0 0.25rem 0.5rem;
                    transform-origin: center;
                    transform: scale(0.9);

                    img {
                        filter: brightness(0) invert(1);
                        opacity: 1;
                    }
                }
            }

            &.action-delete {
                background: $danger;

                &:hover {
                    background: lighten($danger, 15%);
                    box-shadow: none;

                    img {
                        filter: brightness(0) invert(1);
                    }
                }
            }

            &:hover {
                background: $white;
                box-shadow: rgba($primary, 0.25) 0 0.25rem 0.5rem;

                img {
                    filter: none;
                    opacity: 1;
                }
            }

            &:active {
                background: $white;
                box-shadow: rgba($primary, 0.25) 0 0.25rem 0.5rem;
                transform-origin: center;
                transform: scale(0.9);

                img {
                    filter: none;
                    opacity: 1;
                }
            }

            img {
                width: 1rem;
                height: 1rem;
                filter: brightness(0) invert(1);
                @extend .animated;
            }
        }
    }
}
</style>