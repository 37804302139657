<template>
    <div class="form-page">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-5 col-xl-4">
                    <NewProject/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NewProject from "@/components/forms/NewProject";
export default {
    name: "NewProjectPage",
    metaInfo: {
        title: "New Project"
    },
    components: {NewProject}
}
</script>

<style lang="scss">
.form-page {
    margin-top: 55px;
    padding: 30px 0;
    position: relative;
}
</style>