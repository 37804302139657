<template>
    <div class="form-new-row-wrapper">
        <button type="button" @click.prevent="openNewRowColumns" class="form-action form-new-row" v-if="!isColumnsFormOpen">
            <img src="@/assets/icons/plus.svg" alt="">
            <span>Add new row</span>
        </button>
        <div class="form-action-layouts" v-if="isColumnsFormOpen">
            <strong>Choose row layout</strong>
            <div class="form-action-layouts-wrapper">
                <button type="button" @click.prevent="saveRow(1)" class="form-action-columns-choice">
                    <span class="column"></span>
                </button>
                <button type="button" @click.prevent="saveRow(2)" class="form-action-columns-choice">
                    <span class="column"></span>
                    <span class="column"></span>
                </button>
                <button type="button" @click.prevent="saveRow(3)" class="form-action-columns-choice">
                    <span class="column"></span>
                    <span class="column"></span>
                    <span class="column"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NewFormRowWidget",
    props: ['addNewRow'],
    created() {
    },
    data() {
        return {
            isColumnsFormOpen: false,
        }
    },
    methods: {
        openNewRowColumns() {
            this.isColumnsFormOpen = true;
        },
        saveRow(columns) {
            this.isColumnsFormOpen = false;
            this.addNewRow(columns);
        },
    },
}
</script>

<style scoped lang="scss">
@import "../../../scss/variables";

.form-action {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border: none;
    background-color: rgba($primary, 0.05);
    color: $primary;
    border-radius: $box-border-radius;
    font-weight: 500;
    width: 100%;
    box-shadow: none;
    outline: none;
    @extend .animated;
    @extend .disable-selection;

    &:hover {
        background-color: rgba($primary, 0.1);
    }

    &:active {
        background-color: rgba($primary, 0.2);
    }

    img {
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
    }
}

.form-action-layouts {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
    border: 1px dashed rgba($primary, 0.25);
    color: $dark;
    border-radius: $box-border-radius;
    width: 100%;
    box-shadow: none;
    outline: none;
    @extend .disable-selection;

    strong {
        display: block;
        font-weight: 500;
        font-size: 0.9rem;
    }

    .form-action-layouts-wrapper {
        display: flex;
        align-items: center;
        padding-top: 0.5rem;

        .form-action-columns-choice {
            display: flex;
            justify-content: space-between;
            position: relative;
            box-shadow: none;
            outline: none;
            border: none;
            background: none;
            width: 5rem;
            height: 4rem;
            margin: 0 0.25rem;
            padding: 0.5rem;
            border-radius: 0.5rem;
            @extend .animated;

            &:hover {
                background: rgba($primary, 0.1);

                span.column {
                    background: $primary;
                }
            }

            &:active {
                background: rgba($primary, 0.2);

                span.column {
                    background: $primary;
                }
            }

            span.column {
                flex-grow: 1;
                height: calc(100% - 0.3rem);
                border-radius: 0.25rem;
                background: rgba($dark, 0.1);
                margin: 0.15rem;
                @extend .animated;
            }
        }
    }
}
</style>