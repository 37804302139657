<template>
    <form class="element-editor" @submit.prevent="saveChanges(true)">
        <div class="form-field">
            <label for="label" class="form-label">Field Name</label>
            <input type="text" v-model="label" class="form-control" name="label" id="label" required @input="saveChanges">
        </div>
        <div class="form-field" v-if="element.type !== 'date' && element.type !== 'number'">
            <label for="placeholder" class="form-label">Placeholder</label>
            <input type="text" v-model="placeholder" class="form-control" name="placeholder" id="placeholder" @input="saveChanges">
        </div>
        <div class="custom-control custom-checkbox">
            <input class="custom-control-input" type="checkbox" name="required" v-model="required" id="required" @change="saveChanges">
            <label class="custom-control-label" for="required">Required</label>
        </div>
        <div class="form-info">
            <strong>Field Name</strong>
            <span>{{ name }}-{{ element._id }}</span>
        </div>
        <div class="form-info">
            <strong>Field ID</strong>
            <span>#{{ id }}-{{ element._id }}</span>
        </div>
        <button type="submit" class="btn btn-primary">Confirm</button>
    </form>
</template>

<script>
import {generateInputName} from "@/helpers";

export default {
    name: "TextInputElement",
    props: [
        'element',
        'updateElement',
    ],
    data() {
        return {
            name: '',
            id: '',
            label: '',
            placeholder: '',
            required: null,
        }
    },
    created() {
        this.initiateFields(this.element);
    },
    methods: {
        initiateFields(data) {
            if(!data) return;
            this.name = data.name;
            this.id = data.id;
            this.required = data.required;
            this.label = data.label;
            this.placeholder = data.placeholder;
        },
        saveChanges(closeSidebar = false) {
            this.updateElement({
                _id: this.element._id,
                element: this.element.element,
                type: this.element.type,
                name: this.name,
                id: this.id,
                required: this.required,
                label: this.label,
                placeholder: this.placeholder,
            }, closeSidebar);
        },
    },
    watch: {
        element(newData) {
            this.initiateFields(newData);
        },
    },
}
</script>

<style lang="scss">
@import "../../../../scss/variables";

.element-editor {
    .form-field {
        margin-bottom: 1rem;

        &:focus-within {
            .form-label {
                color: $primary;
            }

            .form-control {
                border-color: $primary;
            }
        }

        .form-label {
            //font-size: 0.8rem;
            //color: rgba($dark, 0.75);
            //margin: 0;
            //font-weight: 500;
            display: block;
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 3px;
            color: $dark;
        }

        .form-control {
            //height: auto;
            //padding: 0.25rem 0 0.5rem 0;
            //border-radius: 0;
            //border: none;
            //border-bottom: 1px solid rgba($dark, 0.1);
            //outline: none;
            //box-shadow: none;
            //color: $dark;
            padding: 15px 10px;
            height: auto;
            border-radius: 0.75rem;
            border: 1px solid $border-darker-grey;
            box-shadow: none;
        }
    }

    .custom-control.custom-checkbox {
        padding-left: 0;
        font-size: 1rem;
        color: $grey-text;
        line-height: 1.65;
        margin-bottom: 1rem;
        cursor: pointer;
        @extend .disable-selection;

        a {
            color: $primary;
            font-weight: 500;
            text-decoration: underline;
        }

        .custom-control-label {
            padding-top: 0.25rem;
            padding-left: 2.0rem;
            font-weight: 500;
            color: $dark;
            cursor: pointer;
            display: block;
            width: 100%;

            &:before {
                left: 0;
                width: 1.5rem;
                height: 1.5rem;
                border-color: $border-grey;
            }
            &:after {
                width: 1.5rem;
                height: 1.5rem;
                left: 0;
            }
        }

        .custom-control-input ~ .custom-control-label::before {
            border: 1px solid $dark;
        }

        .custom-control-input:focus ~ .custom-control-label::before {
            box-shadow: rgba($primary, 0.15) 0 0 0 0.2rem;
        }

        .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
            background: rgba($primary, 0.15);
            border-color: rgba($primary, 0.15);
        }

        .custom-control-input:checked ~ .custom-control-label::before {
            background: $primary;
            border-color: $primary;
        }
    }

    .form-info {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 1rem;

        strong {
            font-size: 0.8rem;
            color: rgba($dark, 0.75);
            margin: 0;
            font-weight: 500;
            display: block;
            line-height: 1.2;
        }

        span {
            display: block;
            color: $dark;
            font-size: 0.8rem;
        }
    }
}
</style>