<template>
	<div class="form-group copy-textarea">
        <textarea type="text" class="form-control" readonly :value="content" ref="input"></textarea>
		<button class="btn btn-primary d-block w-100" @click="copy">
			{{ buttonText }}
		</button>
	</div>
</template>

<script>
export default {
    name: "CopyTextarea",
    props: ['button', 'content'],
    data() {
        return {
            copied: false,
        }
    },
    methods: {
        copy() {
            this.$refs.input.select();
            document.execCommand('copy');
            this.copied = true;
            let app = this;
            setTimeout(function () {
                app.copied = false;
            }, 2000);
        }
    },
    computed: {
        buttonText() {
            if (this.copied) return "Copied!";
            return this.button;
        }
    },
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.copy-textarea {
	position: relative;

	.form-control {
		padding: 15px 10px;
        min-height: 10rem;
		height: auto;
        font-size: 1rem;
        border-radius: $box-border-radius;
		border: 1px solid $border-grey;
		background: $border-grey;
        word-break: break-all;

		&:focus {
			box-shadow: none;
			border-color: $primary;
		}
	}

    .btn {
        margin-top: 0.5rem;
    }
}
</style>