<template>
	<div :class="{'tooltip-wrapper': true, 'visible': visible, 'bottom': position === 'bottom'}" @mouseenter="showTooltip" @mouseleave="hideTooltip">
		<div class="tooltip-toggle">
			<slot name="content"></slot>
		</div>
		<div class="tooltip-title">
			{{ title }}
		</div>
	</div>
</template>

<script>
export default {
    name: "Tooltip",
    props: ['title', 'position'],
    data() {
        return {
            visible: false,
        }
    },
    methods: {
        showTooltip() {
            this.visible = true;
        },
        hideTooltip() {
            this.visible = false;
        }
    },
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.tooltip-wrapper {
	position: relative;

    @include smartphone {
        display: none !important;
    }

	.tooltip-title {
		position: relative;
		z-index: 0;

        @include tablet {
            display: none;
        }

        @include smartphone {
            display: none;
        }
	}

	.tooltip-title {
		position: absolute;
		bottom: calc(100% + 5px);
		right: 0;
		left: -100%;
		display: block;
		min-width: 120px;
		max-width: 400px;
		z-index: 1;
        border-radius: $box-border-radius;
		background: $grey-background;
		color: $white;
		font-size: 0.9rem;
		padding: 5px 10px;
		text-align: center;
		opacity: 0;
		visibility: hidden;
		transition: 0.15s ease all;

        @include tablet {
            display: none;
        }

        @include smartphone {
            display: none;
        }
	}

	&.bottom .tooltip-title {
		top: calc(100% + 5px);
		bottom: auto;
	}

	&.size-md .tooltip-title {
		min-width: 220px;
		text-align: left;
	}

	&.visible {
		.tooltip-title {
			opacity: 1;
			visibility: visible;
		}
	}
}
</style>