<template>
	<div class="explanation-wrapper">
		<h1>{{ heading }}</h1>
		<Tooltip :title="explanation" position="bottom" :class="{'tooltip-inline size-md': true, 'd-none': explanation === null || explanation.trim().length === 0}">
			<template v-slot:content>
				<div class="info-circle">i</div>
			</template>
		</Tooltip>
	</div>
</template>

<script>
import Tooltip from "./Tooltip";

export default {
    name: "ExplanationTitle",
    components: {Tooltip},
    props: ['heading', 'explanation'],
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.explanation-wrapper {
	margin-bottom: 1.25rem;
	z-index: 2;
	position: relative;

	h1, .tooltip-inline {
		display: inline-block;
		vertical-align: middle;
		margin: 0;
	}

	.info-circle {
		background: rgba($grey-background, 0.5);
		width: 16px;
		height: 16px;
		margin-left: 5px;
		line-height: 16px;
		border-radius: 50%;
		text-align: center;
		padding: 0;
		color: $white;
		font-size: 1.2rem;
		font-weight: bold;
		font-family: serif;
		cursor: pointer;
		@extend .disable-selection;
		@extend .animated;

		&:hover {
			background: rgba($grey-background, 1);
		}
	}

	@include smartphone {
		margin-bottom: 0.5rem;
	}
}
</style>