import { render, staticRenderFns } from "./FormSettings.vue?vue&type=template&id=4f072e26&scoped=true"
import script from "./FormSettings.vue?vue&type=script&lang=js"
export * from "./FormSettings.vue?vue&type=script&lang=js"
import style0 from "./FormSettings.vue?vue&type=style&index=0&id=4f072e26&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f072e26",
  null
  
)

export default component.exports