<template>
    <div class="form-page">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-5 col-xl-4">
                    <NewForm/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NewForm from "@/components/forms/NewForm";
export default {
    name: "NewFormPage",
    metaInfo: {
        title: "New Form"
    },
    components: {NewForm},
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.form-page {

	@include smartphone {
		margin-top: 0;
		padding: 25px 0 0 0;
		background: $white;
		border-radius: 25px 25px 0 0;
		z-index: 0;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 30px;
			z-index: 0;
			content: ' ';
			background: $primary;
		}

		&:after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 30px;
			background: $white;
			border-radius: 25px 25px 0 0;
			content: ' ';
			z-index: 1;
		}

		.container {
			z-index: 2;
			position: relative;
		}
	}
}
</style>