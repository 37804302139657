<template>
    <div class="form-wrapper">

        <!-- Form Title -->
        <h1>Create New Project</h1>
        <p>With this dashboard, you can easily manage multiple projects at once. To get started, simply enter your project details below.</p>

        <div class="alert alert-danger p-0" v-if="error">{{ error }}</div>

        <form action="#" @submit.prevent="saveProject" :class="{'new-project-form': true, 'is-disabled': canCreateNewProject === false}">

            <!-- Loading effect -->
            <div class="loading" v-if="canCreateNewProject === null">
                <span class="spinner-border"></span>
            </div>

            <!-- Upgrade account -->
            <div class="alert-wrapper" v-if="canCreateNewProject === false">
                <div class="alert alert-info">
                    <img src="../../assets/icons/folder-files.svg" alt="Folder" class="icon">
                    <strong>You have used your account quota for projects.</strong>
                    <p>If you would like to create more projects, please get in touch with us.</p>
                    <a href="mailto:contact@formwise.io" class="btn btn-primary">Contact Us</a>
                </div>
            </div>

            <div class="form-group">
                <label>Name</label>
                <input type="text" class="form-control" placeholder="How's your project called?" v-model="name">
            </div>
            <div class="form-group">
                <label>Website</label>
                <input type="text" class="form-control" placeholder="Where will you collect your submissions?" v-model="website">
            </div>
            <div class="">
                <button type="submit" class="btn btn-primary" style="width: 180px" :disabled="saving">
                    <span class="spinner-border" v-if="saving"></span>
                    <span :class="{'opacity-0': saving}">Save the project</span>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import repository from "@/repository/repository";

export default {
    name: "NewProject",
    components: {},
    data() {
        return {
            name: "",
            website: "",
            error: null,
            saving: false,
            canCreateNewProject: null,
        }
    },
    created() {
        this.checkAvailableProjects();
    },
    methods: {
        saveProject() {
            this.startSaving();
            this.setError(null);
            if (!this.name || this.name.trim().length < 3) {
                this.setError("The project name is required, and must have at least 3 characters.");
                this.endSaving();
                return;
            }
            if (!this.website || !this.verifyWebsite(this.website)) {
                this.setError("A valid website link is required.");
                this.endSaving();
                return;
            }

            // If everything passes, save the project
            repository.post("/projects", {
                name: this.name,
                website: this.website,
            })
                .then(response => {

                    // Reload projects
                    this.$root.$emit("reloadProjects");

                    // Set this as new default project
                    this.$store.commit("updateCurrentProject", response.data.project);

                    // Redirect back to home
                    if(this.$route.path !== "/") {
                        this.$router.replace("/");
                    }
                    this.$root.$emit("closePopups");

                    // Reset fields
                    this.name = "";
                    this.website = "";
                    this.endSaving();
                })
                .catch(error => {
                    if (error.response.data.message) {
                        this.setError(error.response.data.message);
                    } else {
                        this.setError("An error happened while saving your project.");
                    }
                    this.endSaving();
                })
        },
        setError(error) {
            this.error = error;
        },
        verifyWebsite(link) {
            let expression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
            let regex = new RegExp(expression);
            return link.match(regex);
        },
        startSaving() {
            this.saving = true;
        },
        endSaving() {
            this.saving = false;
        },
        checkAvailableProjects() {
            repository.get("/me")
                .then(response => {
                    this.canCreateNewProject = response.data.new_projects;
                })
                .catch(error => {
                    console.log(error);
                    this.$store.commit("logoutUser");
                    this.$router.replace("/login");
                })
        }
    },
}
</script>