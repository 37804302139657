import { render, staticRenderFns } from "./ElementsSidebar.vue?vue&type=template&id=2f14a41c&scoped=true"
import script from "./ElementsSidebar.vue?vue&type=script&lang=js"
export * from "./ElementsSidebar.vue?vue&type=script&lang=js"
import style0 from "./ElementsSidebar.vue?vue&type=style&index=0&id=2f14a41c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f14a41c",
  null
  
)

export default component.exports