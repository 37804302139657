<template>
    <div class="modal-wrapper" @click.prevent="back">
<!--        <div class="modal-overlay" ></div>-->
        <div @click.stop :class="{'modal-box': true, 'modal-large': size === 'large'}">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "Popup",
    props: ['size'],
    methods: {
        back() {
            history.back();
        }
    },
}
</script>

<style lang="scss">
@import "../../scss/variables";

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    padding: 5rem 0;
    opacity: 0;
    visibility: hidden;
    overflow-x: hidden;
    display: block;
    backdrop-filter: blur(2px);
    background: rgba($dark, 0.25);
    @extend .animated;

    @include smartphone {
        display: block;
        overflow-x: hidden;
        padding-bottom: 8rem;
    }

    &.active {
        opacity: 1;
        visibility: visible;

        .modal-overlay {
            opacity: 0.25;
            visibility: visible;
        }

        .modal-box {
            transform: translateY(0);
        }
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        content: ' ';
        z-index: 99;
        opacity: 0;
        visibility: hidden;
        @extend .animated;
    }

    .modal-box {
        border-radius: 1rem;
        border: none;
        box-shadow: $box-shadow-color 0 2px 5px;
        width: 400px;
        background: $white;
        padding: 2.5rem;
        z-index: 100;
        transform: translateY(-50px);
        position: relative;
        margin: auto;
        @extend .animated;

		@include smartphone {
			max-width: 320px;
			padding: 20px 20px;
            margin: auto;
		}

        &.modal-large {
            width: 600px;
        }
    }
}
</style>