/**
 * Converts a date string in the format "2023-08-13"
 * to a formatted string in the style "August 13, 2023"
 *
 * @param date
 * @returns {string}
 */
export function readableDate(date) {
    // Convert the given date string to a Date object
    const dateObject = new Date(date);

    // Options for formatting the date
    let options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };

    // Convert the Date object to the desired format
    return dateObject.toLocaleString('en-US', options);
}

/**
 * Converts any text to kebab-case format for input name & id
 * @param label
 * @returns {string}
 */
export function generateInputName(label) {
    let result = label.toLowerCase();
    result = result.replace(/[^a-z\s]/g, '').replace(/\s+/g, '-');
    result = result.substring(0, 20);
    result = result.replace(/-+$/, '');
    return result;
}