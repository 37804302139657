<template>
    <div :class="{'form-builder-sidebar': true, 'open': open}" ref="formBuilderAddElementSidebar">
        <div class="d-flex justify-content-between">
            <h1>Choose element</h1>
            <button type="button" class="close-sidebar" @click.prevent="closeSidebar">X</button>
        </div>
        <div class="form-fields-list">
            <h4>Most Popular</h4>
            <div class="field-type" @click.stop="selectField('field-name')">
                <img src="@/assets/icons/fields/name.svg" alt="">
                <span>Full Name</span>
            </div>
            <div class="field-type" @click.stop="selectField('field-email')">
                <img src="@/assets/icons/fields/email.svg" alt="">
                <span>Email</span>
            </div>
            <div class="field-type" @click.stop="selectField('field-phone')">
                <img src="@/assets/icons/fields/phone.svg" alt="">
                <span>Phone Number</span>
            </div>
            <div class="field-type" @click.stop="selectField('field-address')">
                <img src="@/assets/icons/fields/address.svg" alt="">
                <span>Address</span>
            </div>

            <h4>Headings & Paragraphs</h4>
            <div class="field-type" @click.stop="selectField('element-heading')">
                <img src="@/assets/icons/fields/heading.svg" alt="">
                <span>Heading</span>
            </div>
            <div class="field-type" @click.stop="selectField('element-paragraph')">
                <img src="@/assets/icons/fields/paragraph.svg" alt="">
                <span>Paragraph</span>
            </div>

            <h4>Basic Fields</h4>
            <div class="field-type" @click.stop="selectField('input-text')">
                <img src="@/assets/icons/fields/text.svg" alt="">
                <span>Short Text</span>
            </div>
            <div class="field-type" @click.stop="selectField('textarea')">
                <img src="@/assets/icons/fields/textarea.svg" alt="">
                <span>Long Text</span>
            </div>
            <div class="field-type" @click.stop="selectField('select')">
                <img src="@/assets/icons/fields/dropdown.svg" alt="">
                <span>Dropdown</span>
            </div>
            <div class="field-type" @click.stop="selectField('input-date')">
                <img src="@/assets/icons/fields/calendar.svg" alt="">
                <span>Date Picker</span>
            </div>
            <div class="field-type" @click.stop="selectField('input-number')">
                <img src="@/assets/icons/fields/number.svg" alt="">
                <span>Number</span>
            </div>
            <div class="field-type" @click.stop="selectField('input-radio')">
                <img src="@/assets/icons/fields/radio.svg" alt="">
                <span>Single Choice</span>
            </div>
            <div class="field-type" @click.stop="selectField('input-checkbox')">
                <img src="@/assets/icons/fields/checkbox.svg" alt="">
                <span>Multiple Choice</span>
            </div>

            <h4>Layout</h4>
            <div class="field-type" @click.stop="selectField('element-hr')">
                <img src="@/assets/icons/fields/line.svg" alt="">
                <span>Horizontal Line</span>
            </div>
            <div class="field-type" @click.stop="selectField('element-spacing')">
                <img src="@/assets/icons/fields/spacing.svg" alt="">
                <span>Spacing</span>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "ElementsSidebar",
    props: ['open', 'addElement', 'closeSidebar'],
    data() {
        return {
        }
    },
    methods: {
        selectField(fieldType) {
            let field = null;

            if(fieldType === 'field-name') {
                field = {
                    element: 'input',
                    type: 'text',
                    name: 'name-field',
                    id: 'name-field',
                    required: true,
                    label: 'Full Name',
                    placeholder: 'Enter your name',
                };
            }

            if(fieldType === 'field-email') {
                field = {
                    element: 'input',
                    type: 'email',
                    name: 'email-field',
                    id: 'email-field',
                    required: true,
                    label: 'Email',
                    placeholder: 'Enter your email address',
                };
            }

            if(fieldType === 'field-phone') {
                field = {
                    element: 'input',
                    type: 'tel',
                    name: 'text-field',
                    id: 'text-field',
                    required: true,
                    label: 'Phone Number',
                    placeholder: 'Enter your phone number',
                };
            }

            if(fieldType === 'field-address') {
                field = {
                    element: 'input',
                    type: 'text',
                    name: 'text-field',
                    id: 'text-field',
                    required: true,
                    label: 'Address',
                    placeholder: 'Enter your full address',
                };
            }

            if(fieldType === 'input-text') {
                field = {
                    element: 'input',
                    type: 'text',
                    name: 'text-field',
                    id: 'text-field',
                    required: false,
                    label: 'Short Text',
                    placeholder: 'Placeholder text..',
                };
            }

            if(fieldType === 'input-date') {
                field = {
                    element: 'input',
                    type: 'date',
                    name: 'date-field',
                    id: 'date-field',
                    required: false,
                    label: 'Date',
                };
            }

            if(fieldType === 'input-number') {
                field = {
                    element: 'input',
                    type: 'number',
                    name: 'number-field',
                    id: 'number-field',
                    required: false,
                    label: 'Number',
                };
            }

            if(fieldType === 'input-radio') {
                field = {
                    element: 'input',
                    type: 'radio',
                    name: 'radio-field',
                    required: false,
                    label: 'Single Choice',
                    options: [
                        {
                            value: 'value-1',
                            text: 'Option 1',
                        },
                        {
                            value: 'value-2',
                            text: 'Option 2',
                        },
                        {
                            value: 'value-3',
                            text: 'Option 3',
                        },
                    ],
                };
            }

            if(fieldType === 'input-checkbox') {
                field = {
                    element: 'input',
                    type: 'checkbox',
                    name: 'checkbox-field',
                    id: 'checkbox-field',
                    required: false,
                    label: 'Multiple Choice',
                    options: [
                        {
                            value: 'value-1',
                            text: 'Option 1',
                        },
                        {
                            value: 'value-2',
                            text: 'Option 2',
                        },
                        {
                            value: 'value-3',
                            text: 'Option 3',
                        },
                    ],
                };
            }

            if(fieldType === 'textarea') {
                field = {
                    element: 'textarea',
                    name: 'textarea-field',
                    id: 'textarea-field',
                    required: false,
                    label: 'Long Text',
                    placeholder: 'Your placeholder..',
                };
            }

            if(fieldType === 'select') {
                field = {
                    element: 'select',
                    name: 'dropdown-field',
                    id: 'dropdown-field',
                    required: false,
                    label: 'Dropdown',
                    options: [
                        {
                            value: 'value-1',
                            text: 'Option 1',
                        },
                        {
                            value: 'value-2',
                            text: 'Option 2',
                        },
                        {
                            value: 'value-3',
                            text: 'Option 3',
                        }
                    ],
                };
            }

            if(fieldType === 'element-heading') {
                field = {
                    element: 'heading',
                    type: 'h2',
                    text: 'Your Heading',
                };
            }

            if(fieldType === 'element-paragraph') {
                field = {
                    element: 'paragraph',
                    text: 'Enter your text here...',
                };
            }

            if(fieldType === 'element-hr') {
                field = {
                    element: 'hr',
                };
            }

            if(fieldType === 'element-spacing') {
                field = {
                    element: 'spacing',
                    size: 30,
                };
            }

            if(field !== null) {
                this.addElement(field);
                this.closeSidebar();
            }
        },
        scrollToTop() {
            this.$refs.formBuilderAddElementSidebar.scrollTop = 0;
        },
    },
}
</script>

<style scoped lang="scss">
@import "../../../scss/variables";

.form-builder-sidebar {
    padding: 1.5rem;
    border-left: 1px solid $border-grey;
    flex-shrink: 0;
    background: $white;
    width: 25rem;
    height: 100%;
    overflow-x: hidden;
    opacity: 0;
    visibility: hidden;
    transform: translateX(100%);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    @extend .animated;

    &.open {
        opacity: 1;
        visibility: visible;
        transform: none;
    }

    h4 {
        font-size: 0.8rem;
        color: rgba($dark, 0.5);
        margin: 1.5rem 0 0.5rem 0;
    }

    .close-sidebar {
        display: block;
        width: 2rem;
        height: 2rem;
        margin-top: -0.5rem;
        border: 1px solid transparent;
        outline: none;
        box-shadow: none;
        background: none;
        border-radius: $box-border-radius;
        position: relative;
        opacity: 0.25;
        @extend .animated;

        &:hover {
            opacity: 1;
            border: 1px solid $border-dark-grey;
        }

        &:active {
            opacity: 1;
            transform: scale(0.95);
        }

        &:before,
        &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 1rem;
            height: 2px;
            background: $dark;
            content: ' ';
            margin: auto;
            transform-origin: center;
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }
}

.field-type {
    display: flex;
    align-items: center;
    border: 1px solid $border-dark-grey;
    border-radius: $box-border-radius;
    padding: 0.75rem;
    font-weight: 500;
    color: $dark;
    cursor: pointer;
    @extend .animated;
    @extend .disable-selection;

    & + .field-type {
        margin-top: 0.5rem;
    }

    &:hover {
        background: $background-grey;
        border-color: transparent;
        color: $primary;

        img {
            filter: none;
            opacity: 1;
        }
    }

    &:active {
        background: rgba($primary, 0.1);
        border-color: transparent;
        color: $primary;
        transform-origin: center;
        transform: scale(0.985);

        img {
            filter: none;
            opacity: 1;
        }
    }

    img {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
        flex-shrink: 0;
        filter: grayscale(1);
        opacity: 0.25;
        @extend .animated;
    }

}
</style>