<template>
	<div class="form-wrapper">

		<!-- Title -->
		<h1>Edit Project</h1>
		<p>With this dashboard, you can efficiently manage multiple projects. To make updates, modify the project details below.</p>

		<div class="alert alert-danger p-0" v-if="error">{{ error }}</div>

		<form action="#" @submit.prevent="saveProject" :class="{'new-project-form': true, 'is-disabled': canEditProject === false}">

			<!-- Loading effect -->
			<div class="loading" v-if="canEditProject === null">
				<span class="spinner-border"></span>
			</div>

			<div class="form-group">
				<label>Name</label>
				<input type="text" class="form-control" placeholder="How's your project called?" v-model="name">
			</div>
			<div class="form-group">
				<label>Website</label>
				<input type="text" class="form-control" placeholder="Where will you collect your submissions?" v-model="website">
			</div>
			<div class="">
				<button type="submit" class="btn btn-primary" style="width: 180px" :disabled="saving">
					<span class="spinner-border" v-if="saving"></span>
					<span :class="{'opacity-0': saving}">Save changes</span>
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import repository from "@/repository/repository";

export default {
	name: "EditProject",
	components: {},
	data() {
		return {
			name: "",
			website: "",
			projectId: null,
			error: null,
			saving: false,
			canEditProject: null,
		}
	},
	created() {
		this.$root.$on('editProjectData', this.handleEditProjectData);
	},
	destroyed() {
		this.$root.$off('editProjectData', this.handleEditProjectData);
	},
	methods: {
		handleEditProjectData(projectData) {
			this.canEditProject = true;
			this.name = projectData.name;
			this.website = projectData.website;
			this.projectId = projectData.hashId;
		},
		saveProject() {
			this.startSaving();
			this.setError(null);
			if (!this.name || this.name.trim().length < 3) {
				this.setError("The project name is required, and must have at least 3 characters.");
				this.endSaving();
				return;
			}
			if (!this.website || !this.verifyWebsite(this.website)) {
				this.setError("A valid website link is required.");
				this.endSaving();
				return;
			}

			// If everything passes, save the project
			repository.put(`/projects/${this.projectId}`, {
				name: this.name,
				website: this.website,
			})
				.then(response => {

					// Reload projects
					this.$root.$emit("reloadProjects");

					// Reload default project
					this.$root.$emit('reloadDefaultProject');

					// Close popups
					this.$root.$emit("closePopups");

					// Reset fields
					this.name = "";
					this.website = "";
					this.projectId = null;
					this.endSaving();
				})
				.catch(error => {
					if (error.response.data.message) {
						this.setError(error.response.data.message);
					} else {
						this.setError("An error happened while saving your project.");
					}
					this.endSaving();
				})
		},
		setError(error) {
			this.error = error;
		},
		verifyWebsite(link) {
			let expression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
			let regex = new RegExp(expression);
			return link.match(regex);
		},
		startSaving() {
			this.saving = true;
		},
		endSaving() {
			this.saving = false;
		}
	},
}
</script>