<template>
    <div class="form-analytics">

        <!-- Form Statistics -->
        <div class="page-wrapper pt-4">

            <!-- Form Stats -->
            <ExplanationTitle heading="Form Insights" explanation="Breakdown of submissions received in the last 30 days."></ExplanationTitle>
            <div class="row md-smaller-margin">
                <div class="col-6 col-xl-3">
                    <div :class="{'stats-box small-box': true, 'loading': !distribution.loaded}">
                        <img src="@/assets/icons/submissions-today.svg" class="icon" alt="Today Submission">
                        <count-to class="count-to" :autoplay="false" :startVal="distribution.oldToday" :endVal="distribution.today" :duration="1000" ref="todaySubmissions"></count-to>
                        <span>Submissions Today</span>
                    </div>
                </div>
                <div class="col-6 col-xl-3">
                    <div :class="{'stats-box small-box': true, 'loading': !distribution.loaded}">
                        <img src="@/assets/icons/submissions-week.svg" class="icon" alt="Weekly">
                        <count-to class="count-to" :autoplay="false" :startVal="distribution.oldWeek" :endVal="distribution.week" :duration="1000" ref="weeklySubmissions"></count-to>
                        <span>Last 7 days</span>
                    </div>
                </div>
                <div class="col-6 col-xl-3">
                    <div :class="{'stats-box small-box': true, 'loading': !distribution.loaded}">
                        <img src="@/assets/icons/submissions-month.svg" class="icon" alt="Monthly">
                        <count-to class="count-to" :autoplay="false" :startVal="distribution.oldMonth" :endVal="distribution.month" :duration="1000" ref="monthlySubmissions"></count-to>
                        <span>Last 30 days</span>
                    </div>
                </div>
                <div class="col-6 col-xl-3">
                    <div :class="{'stats-box small-box': true, 'loading': !distribution.loaded}">
                        <img src="@/assets/icons/submissions-total.svg" class="icon" alt="Total">
                        <count-to class="count-to" :autoplay="false" :startVal="distribution.oldTotal" :endVal="distribution.total" :duration="1000" ref="totalSubmissions"></count-to>
                        <span>Total Submissions</span>
                    </div>
                </div>
            </div>

            <!-- Summary & Actions -->
            <ExplanationTitle class="mb-3 d-none d-md-block" heading="Submissions in the last 30 days" explanation="Here's an overview of the submissions received for this form in the last 30 days."></ExplanationTitle>
            <ExplanationTitle class="mb-3 d-md-none" heading="Last 30 days" explanation="Here's an overview of the submissions received for this form in the last 30 days."></ExplanationTitle>
            <div :class="{'stats-box chart-box': true, 'loading': !loaded}">
                <VueApexCharts type="line" height="200" :options="areaChartOptions" :series="areaSeries"></VueApexCharts>
            </div>

        </div>
    </div>
</template>

<script>
import repository from "@/repository/repository";
import ExplanationTitle from "@/components/widgets/ExplanationTitle";
import countTo from 'vue-count-to';
import VueApexCharts from "vue-apexcharts";

export default {
    name: "FormAnalytics",
    components: {ExplanationTitle, countTo, VueApexCharts},
    props: ['formLink',],
    data() {
        return {
            leadStatus: (this.$route.query.status !== undefined ? this.$route.query.status : "all"),
            form: {
                name: null,
                secret: null,
                color: {
                    background: null,
                    isDarkText: null,
                },
            },
            distribution: {
                new: 0,
                seen: 0,
                today: 0,
                oldToday: 0,
                week: 0,
                oldWeek: 0,
                month: 0,
                oldMonth: 0,
                total: 0,
                oldTotal: 0,
                loaded: false,
            },
            daily: [],
            loaded: false,
        }
    },
    created: function () {
        this.loadSubmissionsDistribution();
    },
    mounted() {
        // Re-load the distribution data when needed
        this.$root.$on('reloadFormSubmissions', this.loadSubmissionsDistribution);
    },
    beforeDestroy() {
        // Remove the event listener when the component is destroyed
        this.$root.$off('reloadFormSubmissions', this.loadSubmissionsDistribution);
    },
    methods: {
        loadSubmissionsDistribution(reload = false) {
            if(!this.projectId) return;
            if(reload) {
                this.distribution.loaded = false;
                this.loaded = false;
            }
            this.distribution.oldToday = this.distribution.today;
            this.distribution.oldWeek = this.distribution.week;
            this.distribution.oldMonth = this.distribution.month;
            this.distribution.oldTotal = this.distribution.total;
            repository.get("/projects/" + this.projectId + "/forms/" + this.formId + "/statistics")
                .then(response => {
                    this.distribution.total = response.data.total;
                    this.distribution.new = response.data.new;
                    this.distribution.seen = response.data.seen;
                    this.distribution.today = response.data.today;
                    this.distribution.week = response.data.week;
                    this.distribution.month = response.data.month;
                    this.distribution.loaded = true;
                    this.daily = response.data.daily;
                    this.form.name = response.data.form.name;
                    this.form.secret = response.data.form.secret;
                    this.form.color.background = response.data.form.color.color;
                    this.form.color.isDarkText = response.data.form.color.text === "dark";
                    this.loaded = true;
                    this.count();
                })
                .catch(() => {
                    console.log("Failed loading submission statistics");
                })
        },
        count() {
            if(this.loaded && this.distribution.total > 0) {
                this.$refs.todaySubmissions.start();
                this.$refs.weeklySubmissions.start();
                this.$refs.monthlySubmissions.start();
                this.$refs.totalSubmissions.start();
            }
        },
        prepareDaily(submissions) {
            if (submissions.length === 0) return [];
            return Object.keys(submissions).map(key => submissions[key]);
        },
    },
    watch: {
        formId() {
            this.loadSubmissionsDistribution(true);
        },
        projectId(newValue, oldValue) {
            if(newValue !== null && oldValue === null) {
                this.loadSubmissionsDistribution();
            } else {
                if (this.$route.path !== "/") this.$router.replace("/");
            }
        },
    },
    computed: {
        formId() {
            return this.$route.params.id;
        },
        projectId() {
            return this.$store.getters.currentProject.hashId;
        },
        areaSeries() {
            if(!this.loaded) return [];
            return [{
                name: "Submissions",
                color: this.form.color.background,
                data: this.prepareDaily(this.daily),
            }];
        },
        areaChartOptions() {
            return {
                chart: {
                    height: this.height,
                    type: 'line',
                    stacked: false,
                    toolbar: {
                        show: false,
                    },
                },
                colors: [
                    "#031feb",
                ],
                fill: {
                    type: 'solid',
                },
                stroke: {
                    curve: 'smooth',
                    width: 3,
                },
                grid: {
                    borderColor: "#eee",
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy'
                    },
                    marker: {
                        show: true,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    labels: {
                        colors: "#aaa",
                    }
                },
                xaxis: {
                    type: 'date',
                    categories: this.categories,
                    labels: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: {
                    max: this.maximumChartValue,
                    labels: {
                        formatter: function (value) {
                            return value.toFixed(0);
                        },
                    },
                },
            };
        },
        height() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return 250
            } else {
                return 200
            }
        },
        categories() {
            if (this.daily.length === 0) return [];
            return Object.keys(this.daily);
        },
        maximumChartValue() {
            let max = 1;
            Object.values(this.daily).forEach(totalSubmissions => {
                if(totalSubmissions > max) {
                    max = totalSubmissions;
                }
            });
            return max + 1;
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.form-analytics {
    background: $background-grey;
    flex-grow: 1;
    min-height: calc(100svh - 44px - 2rem);
}

@include small-desktop {
    .row.md-smaller-margin {
        margin-left: -7.5px;
        margin-right: -7.5px;

        div[class*='col'] {
            padding-left: 7.5px;
            padding-right: 7.5px;
        }
    }
}

@include tablet {
    .row.md-smaller-margin {
        margin-left: -7.5px;
        margin-right: -7.5px;

        div[class*='col'] {
            padding-left: 7.5px;
            padding-right: 7.5px;
        }
    }
}

@include smartphone {
    .row.md-smaller-margin {
        margin-left: -7.5px;
        margin-right: -7.5px;

        div[class*='col'] {
            padding-left: 7.5px;
            padding-right: 7.5px;
        }
    }
}

.stats-box {
    display: block;
    padding: 20px 20px 20px 80px;
    background: $white;
    border-radius: $box-border-radius;
    box-shadow: $box-shadow-color 0 1px 2px;
    position: relative;
    text-decoration: none;
    margin-bottom: 30px;
    overflow: hidden;

	&.small-box {
		height: calc(100% - 30px);

        @include small-desktop {
            height: calc(100% - 15px);
            margin-bottom: 15px;
        }

        @include tablet {
            height: calc(100% - 15px);
            margin-bottom: 15px;
        }
	}

    &.chart-box {
        padding-left: 20px;

        @include smartphone {
            padding: 0;
        }
    }

	@include small-desktop {
		padding: 15px 15px 15px 60px;
	}

    @include tablet {
        padding: 10px 10px 10px 50px;
    }

    @include smartphone {
        margin-bottom: 15px;
        height: auto;
        padding: 15px;

		&.small-box {
			height: auto;
		}
    }

    &:hover {
        box-shadow: $box-shadow-color 0 3px 6px;
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $white;
        z-index: 1;
        content: ' ';
        opacity: 0;
        visibility: hidden;
        transition: 0.15s ease all;
    }

    &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 25px;
        height: 25px;
        border: 2px solid $border-darker-grey;
        border-right-color: transparent;
        content: ' ';
        z-index: 2;
        border-radius: 50%;
        margin: auto;
        animation: spinner-border 0.75s linear infinite;
        opacity: 0;
        visibility: hidden;
        transition: 0.15s ease all;
    }

    &.loading {
        &:before,
        &:after {
            opacity: 1;
            visibility: visible;
            transition: none;
        }
    }

    img.icon {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 25px;
        width: 40px;
        height: 40px;
        user-select: none;
        pointer-events: none;

		@include small-desktop {
			left: 15px;
			width: 30px;
			height: 30px;
		}

        @include tablet {
            left: 15px;
            width: 25px;
            height: 25px;
        }

        @include smartphone {
            position: relative;
            top: 0;
            left: 0;
            margin: 0;
            width: 25px;
            height: 25px;
        }
    }

    .count-to {
        font-weight: 700;
        color: $dark;
        display: block;
        font-size: 2rem;
        margin-bottom: -5px;

        @include smartphone {
            font-size: 1.5rem;
        }
    }

    span {
        font-weight: 400;
        color: $dark;
        display: block;
        margin: 0;

        @include smartphone {
            font-size: 0.8rem;
        }
    }
}

.submissions-wrapper-box {
    background: $white;
    position: sticky;
    top: calc(3.5rem + 20px);

	@include smartphone {
		background: none;
		border: none;
	}

    .submission-categories {
        padding: 1.5rem 1.5rem 0 1.5rem;

		@include smartphone {
            padding: 0.75rem 0 0 0;
		}

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;

			@include smartphone {
				flex-wrap: nowrap;
				overflow-y: hidden;
				padding: 0 15px 15px 15px;
			}

            li {
                display: block;
                margin-right: 0.5rem;

				@include smartphone {
					margin-bottom: 0;
				}

                a {
                    display: flex;
                    align-items: center;
                    padding: 10px 10px;
                    border-radius: $box-border-radius;
                    font-weight: 400;
                    color: $dark;
                    text-decoration: none;
                    font-size: 1rem;
                    @extend .animated;

					@include smartphone {
						width: 100%;
						margin: 0 0.5rem 0 0;
					}

                    &:hover {
                        background: $hover-grey;
                    }

                    &.router-link-active {
                        color: $primary;
                        background: rgba($primary, 0.1);

                        img {
                            filter: none;
                        }

                        strong {
                            opacity: 1;
							color: $primary;
                        }
                    }

                    &.disabled {
                        pointer-events: none;
                        user-select: none;
                        opacity: 0.5;
                    }

					&.loading {
						pointer-events: none;
						user-select: none;
						background: $background-grey;

						img {
							opacity: 0;
						}

						span, strong {
							opacity: 0;
						}
					}

                    img {
                        width: 1.5rem;
                        height: 1.5rem;
                        object-fit: contain;
                        margin-right: 0.5rem;
                        filter: grayscale(1);
                    }

                    span {
                        display: block;
                    }

                    strong {
                        font-size: 1rem;
                        border-radius: $box-border-radius;
                        text-align: center;
                        font-weight: 600;
                        margin-left: 0.5rem;
						color: $dark;

                        &.loading {
                            position: relative;
                            color: transparent;
                            overflow: hidden;

                            &:before {
                                position: absolute;
                                top: 0;
                                left: -80%;
                                width: 80%;
                                height: 100%;
                                background: $active-grey;
                                content: ' ';
                                -webkit-animation: LOADING-EFFECT 0.65s linear infinite; /* Safari 4+ */
                                -moz-animation: LOADING-EFFECT 0.65s linear infinite; /* Fx 5+ */
                                -o-animation: LOADING-EFFECT 0.65s linear infinite; /* Opera 12+ */
                                animation: LOADING-EFFECT 0.65s linear infinite; /* IE 10+, Fx 29+ */

                                @extend .animated;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>