<template>
	<li :class="{'forms-sidebar': true, 'mobile-active': mobileActive}">
        <h4>Forms</h4>
        <a href="#" class="nav-link" @click.prevent="mobileActive = !mobileActive">
            <img src="@/assets/icons/home.svg" alt="Dashboard">
            <span>Forms</span>
        </a>
        <div class="forms-wrapper">
            <div class="dropdown-link loading" v-if="!forms.loaded">
                &nbsp;
            </div>
            <router-link
                :to="'/forms/' + form.hashId"
                class="dropdown-link"
                v-for="(form, key) in forms.items"
                v-bind:key="key">
                <div class="project-color" :style="{'background': getColor(form)}"></div>
                <span>{{ form.name }}</span>
            </router-link>
            <div :class="{'btn btn-primary d-block w-100 mt-3 mb-2': true, 'disabled': forms.loaded && forms.items.length >= 5}" @click.prevent="$root.$emit('openNewFormPopup')">
                <span>Create new form</span>
            </div>
        </div>
    </li>
</template>

<script>
import repository from "@/repository/repository";

export default {
    name: "FormsSidebar",
    data() {
        return {
            mobileActive: false,
        }
    },
    mounted() {
        let app = this;
        // If after X seconds this is still not loaded, simply load the projects
        setTimeout(function () {
            if (!app.forms.loaded) {
                app.loadForms();
            }
        }, 2000);

        // Re-load the forms when needed
        this.reloadFormsHandler = () => {
            this.loadForms(false);
        };

        this.$root.$on('reloadForms', this.reloadFormsHandler);
    },
    beforeDestroy() {
        this.$root.$off('reloadForms', this.reloadFormsHandler);
    },
    methods: {
        getColor(project) {
            if (!project.color) return "rgba(0,0,0,0.1)";
            return project.color.color;
        },
        loadForms(loadingEffect = true) {
            if(loadingEffect) {
                this.$store.commit("loadingForms");
            }
            if(!this.currentProject.loaded) return;
            repository.get("/projects/"+ this.currentProject.hashId +"/forms")
                .then(response => {
                    this.$store.commit("updateForms", response.data);
                })
                .catch(error => {
                    console.log(error);
                })
        },
    },
    computed: {
        forms() {
            return this.$store.getters.forms;
        },
        currentProject() {
            return this.$store.getters.currentProject;
        }
    },
    watch: {
        '$store.state.currentProject.hashId': function() {
            if(this.currentProject.loaded) this.loadForms(false);
        }
    },
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.forms-sidebar {
    margin-top: 1rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    border-top: 1px solid $border-grey;

    @include smartphone {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
        padding-bottom: 0;
        width: 33%;
        flex-shrink: 0;
    }

    h4 {
        font-size: 0.9rem;
        color: $dark;
        font-weight: 600;
        margin: 0 0 10px 0;

        @include smartphone {
            display: none;
        }
    }

    a.nav-link {
        color: $dark;
        border-radius: 0;
        position: relative;
        display: flex;
        align-items: center;
        user-select: none;
        margin: 0 -0.5rem;
        font-size: 0.9rem;
        font-weight: 500;
        margin: 0;
        width: 100%;
        justify-content: center;
        flex-direction: column;
        padding: 0.5rem;
        @extend .animated;

        @include table-and-above {
            display: none;
        }

        &:hover {
            background: $hover-grey;
            color: $primary;

            img {
                filter: none;
                opacity: 1;
            }
        }

        &:active {
            background: $active-grey;
            color: $primary;

            img {
                filter: none;
                opacity: 1;
            }
        }

        &.router-link-exact-active,
        &.router-link-active {
            color: $primary;

            img {
                filter: none;
                opacity: 1;
            }
        }

        img {
            display: block;
            width: 24px;
            height: 24px;
            object-fit: contain;
            filter: brightness(0);
            margin-right: 0;
            margin-bottom: 0.15rem;
        }
    }

    .forms-wrapper {
        @include smartphone {
            position: absolute;
            bottom: 100%;
            box-shadow: rgba($dark, 0.05) 0 -0.25rem 0.75rem;
            padding: 1.5rem;
            left: 0;
            right: 0;
            z-index: 100;
            opacity: 0;
            visibility: hidden;
            background: $white;
        }
    }

    &.mobile-active {
        .forms-wrapper {
            @include smartphone {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .dropdown-link {
        display: block;
        width: calc(100% + 1rem);
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        padding: 0.65rem 0.5rem;
        text-decoration: none;
        background: none;
        border: none;
        outline: none;
        color: $grey-text;
        text-align: left;
        border-radius: $box-border-radius;
        margin-top: 0.25rem;

        @extend .animated;

        &:hover {
            background: $hover-grey;
            color: $primary;
        }

        &:active {
            background: $active-grey;
            color: $primary;
        }

        .project-color {
            display: inline-block;
            vertical-align: middle;
            width: 16px;
            height: 16px;
            border-radius: $box-border-radius;
        }

        span {
            display: inline-block;
            vertical-align: middle;
            width: calc(100% - 21px);
            padding-left: 5px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &.selected,
        &.router-link-active {
            background: rgba($primary, 0.05);
            color: $primary;
        }

        &.loading {
            background: $hover-grey;
            overflow: hidden;
            position: relative;
            width: 100%;
            margin: 0;

            &:before {
                position: absolute;
                top: 0;
                left: -80%;
                width: 80%;
                height: 100%;
                background: $active-grey;
                content: ' ';
                -webkit-animation: LOADING-EFFECT 0.65s linear infinite; /* Safari 4+ */
                -moz-animation: LOADING-EFFECT 0.65s linear infinite; /* Fx 5+ */
                -o-animation: LOADING-EFFECT 0.65s linear infinite; /* Opera 12+ */
                animation: LOADING-EFFECT 0.65s linear infinite; /* IE 10+, Fx 29+ */

                @extend .animated;
            }
        }
    }
}
</style>