<template>
    <div :class="{'form-builder-sidebar': true, 'open': open && element !== null}" @click.stop>
        <div class="d-flex justify-content-between">
            <h1>Edit element</h1>
            <button type="button" class="close-sidebar" @click.prevent="closeSidebar"></button>
        </div>
        <div class="edit-form" v-if="element">
            <TextInputElement
                v-if="(element.element === 'input' || element.element === 'textarea') && (element.type !== 'checkbox' && element.type !== 'radio')"
                :element="element"
                :updateElement="updateElement"
            />

            <CheckboxElement
                v-if="(element.element === 'input' && (element.type === 'checkbox' || element.type === 'radio'))"
                :element="element"
                :updateElement="updateElement"
            />

            <SelectElement
                v-if="(element.element === 'select')"
                :element="element"
                :updateElement="updateElement"
            />

            <HeadingElement
                v-if="element.element === 'heading'"
                :element="element"
                :updateElement="updateElement"
            />

            <ParagraphElement
                v-if="element.element === 'paragraph'"
                :element="element"
                :updateElement="updateElement"
            />

            <SpacingElement
                v-if="element.element === 'spacing'"
                :element="element"
                :updateElement="updateElement"
            />
        </div>
        <div class="delete-field">
            <button class="btn btn-light" @click.prevent="deleteElement">
                <img src="@/assets/icons/trash.svg" alt="">
                <span>Delete Element</span>
            </button>
        </div>
    </div>
</template>

<script>
import TextInputElement from "@/views/Forms/FormBuilder/ElementEditors/TextInputElement.vue";
import HeadingElement from "@/views/Forms/FormBuilder/ElementEditors/HeadingElement.vue";
import ParagraphElement from "@/views/Forms/FormBuilder/ElementEditors/ParagraphElement.vue";
import SpacingElement from "@/views/Forms/FormBuilder/ElementEditors/SpacingElement.vue";
import SelectElement from "@/views/Forms/FormBuilder/ElementEditors/SelectElement.vue";
import CheckboxElement from "@/views/Forms/FormBuilder/ElementEditors/CheckboxElement.vue";

export default {
    name: "EditElementSidebar",
    components: {CheckboxElement, SelectElement, SpacingElement, ParagraphElement, HeadingElement, TextInputElement},
    props: [
        'open',
        'element',
        'updateElement',
        'deleteElement',
        'closeSidebar'
    ],
}
</script>

<style scoped lang="scss">
@import "../../../scss/variables";

.form-builder-sidebar {
    padding: 1.5rem;
    border-left: 1px solid $border-grey;
    flex-shrink: 0;
    background: $white;
    width: 25rem;
    height: 100%;
    overflow-x: hidden;
    opacity: 0;
    visibility: hidden;
    transform: translateX(100%);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    @extend .animated;

    &.open {
        opacity: 1;
        visibility: visible;
        transform: none;
    }

    h4 {
        font-size: 0.8rem;
        color: rgba($dark, 0.5);
        margin: 1.5rem 0 0.5rem 0;
    }

    .close-sidebar {
        display: block;
        width: 2rem;
        height: 2rem;
        margin-top: -0.5rem;
        border: 1px solid transparent;
        outline: none;
        box-shadow: none;
        background: none;
        border-radius: $box-border-radius;
        position: relative;
        opacity: 0.25;
        @extend .animated;

        &:hover {
            opacity: 1;
            border: 1px solid $border-dark-grey;
        }

        &:active {
            opacity: 1;
            transform: scale(0.95);
        }

        &:before,
        &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 1rem;
            height: 2px;
            background: $dark;
            content: ' ';
            margin: auto;
            transform-origin: center;
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    .delete-field {
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        border-top: 1px solid $border-grey;

        .btn {
            display: flex;
            align-items: center;
            padding: 7.5px 10px;
            background: $background-grey;
            border-radius: $box-border-radius;
            border: 1px solid transparent;
            position: relative;
            text-decoration: none;
            color: $dark;
            font-size: 0.9rem;
            font-weight: 500;
            box-shadow: none;
            @extend .animated;

            @include tablet {
                padding: 15px 15px;
            }

            @include small-desktop {
                padding: 16.5px 15px;
            }

            img {
                width: 1.25rem;
                height: 1.25rem;
                filter: grayscale(1);
                margin: 0 0.5rem 0 0;
                opacity: 0.25;
                @extend .animated;
            }

            .spinner-border {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 1.5rem;
                height: 1.5rem;
                border-width: 2px;
                opacity: 0.25;
            }

            &:hover {
                background: lighten($primary, 50%);
                border-color: transparent;
                color: $primary;
                box-shadow: none;

                img {
                    opacity: 1;
                    filter: none;
                }
            }

            &:active {
                background: lighten($primary, 45%);
                border-color: transparent;
                transform-origin: center;
                transform: scale(0.9875);
                color: $primary;
                box-shadow: none;

                img {
                    opacity: 1;
                    filter: none;
                }
            }

            &.disabled {
                @extend .disable-clicks;
                opacity: 0.5;
                filter: grayscale(1);
            }

            &.loading {
                @extend .disable-clicks;

                img, .form-link-content {
                    opacity: 0;
                }
            }
        }
    }
}
</style>