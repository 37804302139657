<template>
    <form class="element-editor" @submit.prevent="saveChanges(true)">
        <div class="form-field">
            <label for="label" class="form-label">Spacing Size</label>
            <div class="field-radio-group" role="group" aria-label="Heading Type">
                <div class="field-radio-item">
                    <input type="radio" class="btn-check" name="size" id="size-20" autocomplete="off" v-model="size" :value="20" @change="saveChanges">
                    <label class="btn" for="size-20">20px</label>
                </div>
                <div class="field-radio-item">
                    <input type="radio" class="btn-check" name="size" id="size-25" autocomplete="off" v-model="size" :value="25" @change="saveChanges">
                    <label class="btn" for="size-25">25px</label>
                </div>
                <div class="field-radio-item">
                    <input type="radio" class="btn-check" name="size" id="size-30" autocomplete="off" v-model="size" :value="30" @change="saveChanges">
                    <label class="btn" for="size-30">30px</label>
                </div>
                <div class="field-radio-item">
                    <input type="radio" class="btn-check" name="size" id="size-50" autocomplete="off" v-model="size" :value="50" @change="saveChanges">
                    <label class="btn" for="size-50">50px</label>
                </div>
                <div class="field-radio-item">
                    <input type="radio" class="btn-check" name="size" id="size-75" autocomplete="off" v-model="size" :value="75" @change="saveChanges">
                    <label class="btn" for="size-75">75px</label>
                </div>
            </div>
        </div>
        <button type="submit" class="btn btn-primary">Confirm</button>
    </form>
</template>

<script>
export default {
    name: "SpacingElement",
    props: [
        'element',
        'updateElement',
    ],
    data() {
        return {
            size: '',
        }
    },
    created() {
        this.initiateFields(this.element);
    },
    methods: {
        initiateFields(data) {
            if(!data) return;
            this.size = data.size;
        },
        saveChanges(closeSidebar = false) {
            this.updateElement({
                _id: this.element._id,
                element: this.element.element,
                size: this.size,
            }, closeSidebar);
        },
    },
    watch: {
        element(newData) {
            this.initiateFields(newData);
        },
    },
}
</script>

<style scoped lang="scss">
@import "../../../../scss/variables";

.element-editor {
    .field-radio-group {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .field-radio-item {
            display: block;
            margin-right: 0.25rem;

            input {
                display: none;

                &:checked ~ .btn {
                    background: $primary;
                    color: $white;
                }
            }

            .btn {
                display: block;
                margin: 0;
                width: auto;
                height: auto;
                font-size: 0.9rem;
                font-weight: 500;
                color: $dark;
                background: $background-grey;
                border-radius: $box-border-radius;
                @extend .animated;

                &:hover {
                    background: rgba($primary, 0.2);
                    color: $primary;
                }
            }
        }
    }
}
</style>