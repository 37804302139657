<template>
    <div class="form-group honeypot-field">
        <form action="#" method="post" @submit.prevent="saveField">
            <label for="honeypot-field" class="form-label">HoneyPot Fields</label>
            <input type="text" class="form-control" placeholder="Enter input name" v-model="field" id="honeypot-field">
            <button type="submit" class="btn btn-sm btn-keyboard" v-if="field && field.length > 0">↳ Enter</button>
        </form>
        <p class="small mb-3">Spam bots usually fill all fields on your form. Add some fields that you do not need, but that can trick the bots to fill them.</p>
        <ul class="honeypot-fields-list" v-if="honeypotFields.length > 0">
            <li v-for="(field, key) in honeypotFields" :key="key">
                <strong>{{ field }}</strong>
                <button class="btn btn-icon btn-sm" @click.prevent="deleteField(field)">
                    <img src="@/assets/icons/trash.svg" alt="Delete">
                </button>
            </li>
        </ul>
        <div class="input-fields-code" v-if="honeypotFields.length > 0">
            <h6>Please add this HTML on your form</h6>
            <CodeField :code="inputsCode" type="HTML"></CodeField>
            <textarea v-model="inputsCode" ref="inputsCode"></textarea>
            <button type="button" class="btn btn-outline-dark" @click.prevent="copyCode" style="width: 150px;">
                <span v-if="!copied">Copy HTML</span>
                <span v-if="copied">Copied!</span>
            </button>
        </div>
    </div>
</template>

<script>
import CodeField from "@/components/widgets/CodeField.vue";

export default {
    name: "HoneyPotFields",
    components: {CodeField},
    props: [ 'value' ],
    data() {
        return {
            field: null,
            honeypotFields: this.value,
            copied: false,
        }
    },
    methods: {
        deleteField(value) {
            this.honeypotFields.splice(this.honeypotFields.findIndex(field => field === value), 1);
            // TODO: Propagate changes to main component
            // this.$emit('input', this.recipients);
        },
        saveField() {
            if(!this.field || this.field.trim().length <= 0) {
                return;
            }
            this.honeypotFields.push(this.field);
            this.field = null;
            // TODO: Propagate changes to main component
            // this.$emit('input', this.recipients);
        },
        copyCode() {
            this.$refs.inputsCode.select();
            document.execCommand('copy');
            this.copied = true;
            let app = this;
            setTimeout(function () {
                app.copied = false;
            }, 2000);
        },
    },
    computed: {
        inputsCode() {
            let inputsCode = "<!-- Code generated by FormWise.io -->";
            this.honeypotFields.forEach((field, key) => {
                if(key === 0) {
                    inputsCode += '\n<!-- <input type="hidden" name="'+ field +'" value="1"> -->';
                } else {
                    inputsCode += '\n<input type="hidden" name="'+ field +'">';
                }
            });
            return inputsCode;
        },
    },
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.honeypot-field {
    margin-bottom: 0;

    .honeypot-fields-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;
            padding: 0.25rem 0.75rem;
            border-radius: 1rem;
            background: rgba($dark, 0.1);
            margin-bottom: 0.25rem;
            margin-right: 0.25rem;
            position: relative;
            z-index: 1;
            @extend .disable-selection;

            strong {
                font-weight: 600;
                font-size: 0.9rem;
                color: $dark;
            }

            .btn.btn-icon {
                padding: 0;
                margin-left: 0.25rem;
                margin-top: -0.15rem;
                opacity: 0.25;
                line-height: 1;
                transition: 0.15s ease all;

                &:hover {
                    opacity: 0.75;
                }

                img {
                    width: 1rem;
                    height: 1rem;
                    filter: grayscale(1);
                }
            }
        }
    }

    form {
        .btn.btn-keyboard {
            position: absolute;
            top: 2.65rem;
            right: 0.5rem;
            background: darken($background-grey, 2.5%);
            color: $dark;
            font-size: 0.8rem;
            font-weight: 600;
            border-radius: 0.5rem;
            text-transform: uppercase;

            &:hover {
                background: darken($background-grey, 5%);
            }

            &:active {
                background: darken($background-grey, 7.5%);
                transform-origin: center;
                transform: scale(0.9);
            }
        }
    }

    .input-fields-code {
        padding: 1rem 0;
        position: relative;

        h6 {
            font-size: 0.9rem;
            color: $dark;
            font-weight: 500;
            margin-bottom: 1rem;
        }

        textarea {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: -1;
        }
    }
}
</style>