<template>
    <form class="element-editor" @submit.prevent="saveChanges(true)">
        <div class="form-field">
            <label for="text" class="form-label">Content</label>
            <textarea v-model="text" class="form-control" name="text" id="text" style="min-height: 10rem;" @input="saveChanges"></textarea>
        </div>
        <button type="submit" class="btn btn-primary">Confirm</button>
    </form>
</template>

<script>
export default {
    name: "ParagraphElement",
    props: [
        'element',
        'updateElement',
    ],
    data() {
        return {
            text: '',
        }
    },
    created() {
        this.initiateFields(this.element);
    },
    methods: {
        initiateFields(data) {
            if(!data) return;
            this.text = data.text;
        },
        saveChanges(closeSidebar = false) {
            this.updateElement({
                _id: this.element._id,
                element: this.element.element,
                text: this.text,
            }, closeSidebar);
        },
    },
    watch: {
        element(newData) {
            this.initiateFields(newData);
        },
    },
}
</script>

<style scoped lang="scss">
@import "../../../../scss/variables";

</style>