<template>
    <form class="element-editor select-editor" @submit.prevent="saveChanges(true)">
        <div class="form-field">
            <label for="label" class="form-label">Field Name</label>
            <input type="text" v-model="label" class="form-control" name="label" id="label" required @input="saveChanges">
        </div>
        <div class="custom-control custom-checkbox">
            <input class="custom-control-input" type="checkbox" name="required" v-model="required" id="required" @change="saveChanges">
            <label class="custom-control-label" for="required">Required</label>
        </div>
        <div class="form-options">
            <label class="form-label">Dropdown Options</label>
            <div class="form-option" v-for="(option, optionKey) in options" :key="optionKey">
                <div class="form-field-inline">
                    <input type="text" class="form-control" required :id="'text-' + optionKey" v-model="option.text" @input="updateOption(option)">
                </div>
                <button type="button" class="btn btn-danger option-delete" @click.prevent="deleteOption(optionKey)" tabindex="-1">
                    <img src="@/assets/icons/trash.svg" alt="">
                </button>
            </div>
            <button type="button" @click.prevent="addOption" class="btn btn-light">
                <img src="@/assets/icons/plus.svg" alt="">
                <span>Add Option</span>
            </button>
        </div>
        <div class="form-info">
            <strong>Field Name</strong>
            <span>{{ name }}-{{ element._id }}</span>
        </div>
        <div class="form-info">
            <strong>Field ID</strong>
            <span>#{{ id }}-{{ element._id }}</span>
        </div>
        <button type="submit" class="btn btn-primary">Confirm</button>
    </form>
</template>

<script>
import {generateInputName} from "@/helpers";

export default {
    name: "SelectElement",
    props: [
        'element',
        'updateElement',
    ],
    data() {
        return {
            name: '',
            id: '',
            label: '',
            required: null,
            options: [],
        }
    },
    created() {
        this.initiateFields(this.element);
    },
    methods: {
        initiateFields(data) {
            if(!data) return;
            this.name = data.name;
            this.id = data.id;
            this.required = data.required;
            this.label = data.label;
            this.options = data.options;
        },
        saveChanges(closeSidebar = false) {
            this.updateElement({
                _id: this.element._id,
                element: this.element.element,
                name: this.name,
                id: this.id,
                required: this.required,
                label: this.label,
                options: this.options,
            }, closeSidebar);
        },
        addOption() {
            this.options.push({
                value: 'New Value',
                text: 'New Value',
            });
            this.saveChanges();
        },
        updateOption(option) {
            option.value = option.text;
            this.saveChanges();
        },
        deleteOption(index) {
            this.options.splice(index, 1);
        },
    },
    watch: {
        element(newData) {
            this.initiateFields(newData);
        },
    },
}
</script>

<style lang="scss">
@import "../../../../scss/variables";

.element-editor.select-editor {
    .form-options {
        margin-bottom: 1rem;

        .form-label {
            display: block;
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 3px;
            color: $dark;
        }

        .form-option {
            background: $background-grey;
            border-radius: $box-border-radius;
            padding: 1rem 2rem 1rem 1rem;
            margin-bottom: 0.5rem;
            position: relative;

            &:hover {
                .btn.option-delete {
                    opacity: 1;
                }
            }

            .form-field-inline {
                display: flex;
                align-items: center;

                &:focus-within {
                    label {
                        color: $primary;
                    }

                    .form-control {
                        border-color: $primary;
                    }
                }

                & + .form-field-inline {
                    margin-top: 0.5rem;
                }

                label {
                    width: 25%;
                    flex-shrink: 0;
                    font-size: 0.9rem;
                    font-weight: 500;
                    color: $dark;
                    margin: 0;
                }

                .form-control {
                    padding: 5px 10px;
                    height: auto;
                    border-radius: 0.75rem;
                    border: 1px solid $border-darker-grey;
                    box-shadow: none;
                }
            }

            .btn.option-delete {
                line-height: 1rem;
                text-align: center;
                display: block;
                background: $danger;
                padding: 0;
                border: none;
                outline: none;
                box-shadow: none;
                width: 2rem;
                height: 2rem;
                border-radius: $box-border-radius;
                position: absolute;
                top: 0;
                bottom: 0;
                right: -1rem;
                margin: auto;
                opacity: 0;
                @extend .animated;

                img {
                    width: 1rem;
                    height: 1rem;
                    filter: brightness(0) invert(1);
                    @extend .animated;
                }

                &:hover {
                    background: $danger;
                }

                &:active {
                    background: $danger;
                    transform-origin: center;
                    transform: scale(0.9);
                }
            }
        }

        .btn.btn-light {
            display: flex;
            align-items: center;
            padding: 7.5px 10px;
            background: $background-grey;
            border-radius: $box-border-radius;
            border: 1px solid transparent;
            position: relative;
            text-decoration: none;
            color: $dark;
            font-size: 0.9rem;
            font-weight: 500;
            box-shadow: none;
            @extend .animated;

            @include tablet {
                padding: 15px 15px;
            }

            @include small-desktop {
                padding: 16.5px 15px;
            }

            img {
                width: 1.25rem;
                height: 1.25rem;
                filter: grayscale(1);
                margin: 0 0.5rem 0 0;
                opacity: 0.25;
                @extend .animated;
            }

            .spinner-border {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 1.5rem;
                height: 1.5rem;
                border-width: 2px;
                opacity: 0.25;
            }

            &:hover {
                background: lighten($primary, 50%);
                border-color: transparent;
                color: $primary;
                box-shadow: none;

                img {
                    opacity: 1;
                    filter: none;
                }
            }

            &:active {
                background: lighten($primary, 45%);
                border-color: transparent;
                transform-origin: center;
                transform: scale(0.9875);
                color: $primary;
                box-shadow: none;

                img {
                    opacity: 1;
                    filter: none;
                }
            }
        }
    }
}
</style>