<template>
    <form class="element-editor select-editor" @submit.prevent="saveChanges(true)">
        <div class="form-field">
            <label for="label" class="form-label">Field Name</label>
            <input type="text" v-model="label" class="form-control" name="label" id="label" required @input="saveChanges">
        </div>
        <div class="custom-control custom-checkbox">
            <input class="custom-control-input" type="checkbox" name="required" v-model="required" id="required" @change="saveChanges">
            <label class="custom-control-label" for="required">Required</label>
        </div>
        <div class="form-options">
            <label class="form-label">Options</label>
            <div class="form-option" v-for="(option, optionKey) in options" :key="optionKey">
                <div class="form-field-inline">
                    <input type="text" class="form-control" required :id="'text-' + optionKey" v-model="option.text" @input="updateOption(option)">
                </div>
                <button type="button" class="btn btn-danger option-delete" @click.prevent="deleteOption(optionKey)" tabindex="-1">
                    <img src="@/assets/icons/trash.svg" alt="">
                </button>
            </div>
            <button type="button" @click.prevent="addOption" class="btn btn-light">
                <img src="@/assets/icons/plus.svg" alt="">
                <span>Add Option</span>
            </button>
        </div>
        <div class="form-info">
            <strong>Field Name</strong>
            <span>{{ name }}-{{ element._id }}</span>
        </div>
        <div class="form-info">
            <strong>Field ID</strong>
            <span>#{{ id }}-{{ element._id }}</span>
        </div>
        <button type="submit" class="btn btn-primary">Confirm</button>
    </form>
</template>

<script>
import {generateInputName} from "@/helpers";

export default {
    name: "CheckboxElement",
    props: [
        'element',
        'updateElement',
    ],
    data() {
        return {
            name: '',
            id: '',
            label: '',
            required: null,
            options: [],
        }
    },
    created() {
        this.initiateFields(this.element);
    },
    methods: {
        initiateFields(data) {
            if(!data) return;
            this.name = data.name;
            this.id = data.id;
            this.required = data.required;
            this.label = data.label;
            this.options = data.options;
        },
        saveChanges(closeSidebar = false) {
            this.updateElement({
                _id: this.element._id,
                element: this.element.element,
                type: this.element.type,
                name: this.name,
                id: this.id,
                required: this.required,
                label: this.label,
                options: this.options,
            }, closeSidebar);
        },
        addOption() {
            this.options.push({
                value: 'New Value',
                text: 'New Value',
            });
            this.saveChanges();
        },
        updateOption(option) {
            option.value = option.text;
            this.saveChanges();
        },
        deleteOption(index) {
            this.options.splice(index, 1);
        },
    },
    watch: {
        element(newData) {
            this.initiateFields(newData);
        },
    },
}
</script>

<style lang="scss">
@import "../../../../scss/variables";

</style>