<template>
	<div class="page-wrapper projects-wrapper">
		<h1>Projects</h1>

        <!-- Loading effect -->
		<div class="row" v-if="!projects.loaded">
			<div class="col-md-4 col-lg-4 col-xl-3 mb-4">
				<ProjectBox></ProjectBox>
			</div>
		</div>

        <!-- Results -->
        <div class="row" v-if="projects.loaded">
            <div class="col-md-12 col-lg-6 col-xl-3 mb-4" v-for="(project, key) in projects.items" :key="key">
                <ProjectBox :project="project"></ProjectBox>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3 mb-4">
                <button @click.prevent="$root.$emit('openNewProjectPopup')" class="new-project-box">
                    <img src="@/assets/icons/plus.svg" alt="">
                    <strong>Create New Project</strong>
                </button>
            </div>
        </div>
	</div>
</template>

<script>

import ProjectBox from "@/components/models/ProjectBox";

export default {
	name: "Projects",
	components: {ProjectBox},
	computed: {
		projects() {
			this.$root.$emit("reloadProjects");
			return this.$store.getters.projects;
		},
		currentProject() {
			return this.$store.getters.currentProject;
		},
	}
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.projects-wrapper {
    background: $background-grey;

    .new-project-box {
        display: block;
        height: 100%;
        width: 100%;
        background: $primary;
        border: none;
        border-radius: $box-border-radius;
        padding: 2.5rem;
        outline: none;
        box-shadow: none;
        @extend .animated;
        @extend .disable-selection;

        &:hover {
            background: lighten($primary, 5%);
        }

        &:active {
            background: lighten($primary, 10%);
            transform-origin: center;
            transform: scale(0.985);
        }

        img {
            width: 4rem;
            height: 4rem;
            filter: brightness(0) invert(1);
            display: block;
            margin: auto;
        }

        strong {
            display: block;
            margin: 1rem 0 0 0;
            color: $white;
            font-weight: 600;
            font-size: 1rem;
        }
    }
}
</style>