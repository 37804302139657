<template>
	<Dropdown class="color-picker" position="right">
		<template v-slot:toggle>
			<div class="current-color">
				<span v-if="loaded && current !== null" :style="{'background': current.color}"></span>
			</div>
		</template>
		<template v-slot:content>
			<div class="dropdown-link" @click.prevent="saveColorChoice(color)" v-for="(color, key) in colors" :key="key">
				<div class="project-color" :style="{'background': color.color}"></div>
				<span>{{ color.name }}</span>
			</div>
		</template>
	</Dropdown>
</template>

<script>
import repository from "@/repository/repository";
import Dropdown from "@/components/widgets/Dropdown";

export default {
    name: "ColorPicker",
    props: ['value'],
    components: {Dropdown},
    data() {
        return {
            colors: [],
            loaded: false,
            current: this.value,
        }
    },
    created() {
        repository.get("/colors")
            .then(response => {
                this.colors = response.data;
                this.loaded = true;
                if (this.current === null) {
                    this.randomValue();
                    this.$emit('input', this.current.id);
                } else {
                    this.current = this.colors.filter(item => item.id == this.value)[0];
                }
            })
            .catch(error => {
                console.log(error);
            })
    },
    methods: {
        saveColorChoice(color) {
            this.current = color;
            this.$emit('input', this.current.id);
        },
        shuffle(a) {
            let j, x, i;
            for (i = a.length - 1; i > 0; i--) {
                j = Math.floor(Math.random() * (i + 1));
                x = a[i];
                a[i] = a[j];
                a[j] = x;
            }
            return a;
        },
        randomValue() {
            this.current = this.shuffle([...this.colors])[0];
            this.$emit('input', this.current.id);
        },
    },
    computed: {
        dropdown() {
            return this.$store.getters.dropdown;
        }
    },
    watch: {
        value(colorId) {
            if (colorId !== null && colorId !== undefined) {
                this.current = this.colors.filter(item => item.id == colorId)[0];
            }
        }
    },
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.current-color {
	width: 51px;
	height: 51px;
	border: 1px solid transparent;
	border-radius: 0.75rem;
	position: relative;
    @extend .animated;

	&:hover,
	&:focus {
        background: rgba($dark, 0.1);
	}

	&:active {
        background: rgba($dark, 0.2);
	}

	span {
		position: absolute;
		top: 10px;
		bottom: 10px;
		left: 10px;
		right: 10px;
		border-radius: $box-border-radius;
		display: block;
	}

	.spinner-border {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		border-color: $border-darker-grey;
		border-right-color: transparent;
	}
}

.dropdown-link {
	cursor: pointer;

	.project-color {
		display: inline-block;
		vertical-align: middle;
		width: 16px;
		height: 16px;
		border-radius: $box-border-radius;
	}

	span {
		display: inline-block;
		vertical-align: middle;
		width: calc(100% - 21px);
		padding-left: 5px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
</style>