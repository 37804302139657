import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from "@/store";
import Login from "@/views/Login";
// import Register from "@/views/Register";
import FormDetails from "@/views/Forms/FormDetails";
import FormSubmissions from "@/views/Forms/FormSubmissions";
import FormIntegration from "@/views/Forms/FormIntegration";
import FormSettings from "@/views/Forms/FormSettings";
import Submissions from "@/views/Submissions/Submissions";
import SubmissionDetails from "@/views/Submissions/SubmissionDetails";
import Tags from "@/views/Tags/Tags";
import NewProjectPage from "@/views/Projects/NewProjectPage";
import NewFormPage from "@/views/Forms/NewFormPage";
import Projects from "@/views/Projects/Projects.vue";
import NoSubmissionSelectedView from "@/views/Submissions/NoSubmissionSelectedView.vue";
import FormAnalytics from "@/views/Forms/FormAnalytics.vue";
import Transactions from "@/views/Payments/Transactions.vue";
import PurchaseSubmissions from "@/views/Payments/PurchaseSubmissions.vue";
import FormBuilder from "@/views/Forms/FormBuilder.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Home,
        meta: {
            requireAuth: true,
            requireGuest: false,
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requireAuth: false,
            requireGuest: true,
        },
    },
    {
        path: '/auth',
        name: 'Auth',
        component: Login,
        meta: {
            requireAuth: false,
            requireGuest: true,
        },
        props: (route) => ({ token: route.query.t }),
    },
    // {
    //     path: '/register',
    //     name: 'Register',
    //     component: Register,
    //     meta: {
    //         requireAuth: false,
    //         requireGuest: true,
    //     },
    // },
    {
        path: '/logout',
        name: 'Logout',
        component: function () {
            store.commit("logoutUser");
            router.push("/login");
        },
        meta: {
            requireAuth: true,
            requireGuest: false,
        },
    },
    {
        path: '/projects',
        name: 'Projects',
        component: Projects,
        meta: {
            requireAuth: true,
            requireGuest: false,
        }
    },
    {
        path: '/projects/new',
        name: 'New Project',
        component: NewProjectPage,
        meta: {
            requireAuth: true,
            requireGuest: false,
        },
    },
    {
        path: '/forms/new',
        name: 'New Form',
        component: NewFormPage,
        meta: {
            requireAuth: true,
            requireGuest: false,
        },
    },
    {
        path: '/forms/:id',
        component: FormDetails,
        children: [
            {
                path: '',
                name: 'Form Submissions',
                component: FormSubmissions,
            },
            {
                path: 'analytics',
                name: 'Analytics',
                component: FormAnalytics,
            },
            {
                path: 'integration',
                name: 'Form Integration',
                component: FormIntegration,
            },
            {
                path: 'settings',
                name: 'Form Settings',
                component: FormSettings,
            },
            {
                path: 'builder',
                name: 'Form Build',
                component: FormBuilder,
            },
        ],
        meta: {
            requireAuth: true,
            requireGuest: false,
        },
    },
    {
        path: '/submissions',
        name: 'Submissions',
        component: Submissions,
        meta: {
            requireAuth: true,
            requireGuest: false,
        },
        children: [
            {
                path: '/submissions',
                name: 'No Submission',
                component: NoSubmissionSelectedView,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
            {
                path: '/submissions/:id',
                name: 'Submission Details',
                component: SubmissionDetails,
                meta: {
                    requireAuth: true,
                    requireGuest: false,
                },
            },
        ],
    },
    {
        path: '/tags',
        name: 'Tags',
        component: Tags,
        meta: {
            requireAuth: true,
            requireGuest: false,
        }
    },
    {
        path: '/transactions',
        name: 'Transactions',
        component: Transactions,
        meta: {
            requireAuth: true,
            requireGuest: false,
        }
    },
    {
        path: '/purchase-submissions',
        name: 'PurchaseSubmissions',
        component: PurchaseSubmissions,
        meta: {
            requireAuth: true,
            requireGuest: false,
        }
    },
    {
        path: '*',
        redirect: {
            path: '/',
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireAuth)) {
        if (store.getters.token == null) {
            // If no user is signed in, simply redirect to /login
            next({
                path: "/login",
                query: {
                    redirect: to.fullPath
                }
            });
        } else {
            // Continue if ok
            next();
        }
    } else if (to.matched.some(record => record.meta.requireGuest)) {
        if (store.getters.token != null) {
            // If there is a user, simply redirect to home
            next({
                path: "/"
            });
        } else {
            next();
        }
    } else {
        // Simply continue
        next();
    }
});

export default router
