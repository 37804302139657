<template>
    <div class="form-field-column">
        <div class="form-column-fields">
            <slot></slot>
        </div>
        <button type="button" @click.stop="openSidebar" :class="{'form-field-column-add-fields': true, 'selected': currentColumn}" v-if="column.fields.length === 0">
            <img src="@/assets/icons/plus.svg" alt="" v-if="!currentColumn">
            <span v-if="!currentColumn">Add form element</span>
            <span v-if="currentColumn">Select element..</span>
        </button>
    </div>
</template>

<script>
export default {
    name: "FormColumn",
    props: ['column', 'columnId', 'rowId', 'currentColumn', 'openElementSidebar'],
    methods: {
        openSidebar() {
            this.openElementSidebar(this.rowId, this.columnId);
        },
    },
}
</script>

<style scoped lang="scss">
@import "../../../scss/variables";

.form-field-column {
    flex: 1;
    margin: 0 0.35rem;

    .form-field-column-add-fields {
        padding: 0 0.75rem;
        font-size: 0.9rem;
        color: rgba($dark, 0.65);
        font-weight: 500;
        width: 100%;
        height: calc(4.5rem + 5.5px);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;
        background: rgba($background-grey, 0.75);
        outline: none;
        box-shadow: none;
        border-radius: $box-border-radius;
        @extend .disable-selection;
        @extend .animated;

        &.selected {
            background: none;
            border: 1px solid $primary;
            color: $primary;

            img {
                filter: none;
                opacity: 1;
            }
        }

        &:hover {
            background: rgba($primary, 0.075);
            color: $primary;

            img {
                filter: none;
                opacity: 1;
            }
        }

        &:active {
            background: rgba($primary, 0.15);
            color: $primary;

            img {
                filter: none;
                opacity: 1;
            }
        }

        img {
            width: 1.25rem;
            height: 1.25rem;
            margin-right: 0.25rem;
            flex-shrink: 0;
            filter: grayscale(1);
            opacity: 0.125;
            @extend .animated;
        }
    }
}
</style>