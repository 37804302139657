<template>
	<div class="page-wrapper">
        <ExplanationTitle heading="Your Tags" explanation="You have the option to assign tags to your submissions."></ExplanationTitle>

        <!-- New Tags -->
        <NewTag/>

        <!-- Loading -->
        <div class="tags-wrapper" v-if="!tags.loaded">
            <TagBox/>
            <TagBox/>
            <TagBox/>
        </div>

        <!-- Tags -->
        <div class="tags-wrapper" v-if="tags.loaded">
            <TagBox :data="tag" v-for="(tag) in tags.items" :key="tag.hashId"></TagBox>
            <p v-if="tags.items.length === 0">You can create custom tags based on your specific needs to easily categorize and organize your submissions.</p>
        </div>
	</div>
</template>

<script>
import repository from "../../repository/repository";
import TagBox from "../../components/models/TagBox";
import ExplanationTitle from "../../components/widgets/ExplanationTitle";
import NewTag from "./NewTag";

export default {
    name: "Tags",
    metaInfo: {
        title: "Tags"
    },
    components: {NewTag, ExplanationTitle, TagBox},
    created() {
        this.loadTags();
    },
    methods: {
        loadTags() {
            this.$store.commit("loadingTags");
            repository.get("/tags")
                .then(response => {
                    this.$store.commit("updateTags", response.data);
                })
                .catch(error => {
                    console.log(error);
                })
        },
    },
    computed: {
        tags() {
            return this.$store.getters.tags;
        }
    },
}
</script>


<style lang="scss" scoped>
@import "src/scss/variables";

.page-wrapper {
    @include smartphone {
        background: $white;

        &:after {
            background: $white;
        }
    }
}
</style>