import { render, staticRenderFns } from "./Tags.vue?vue&type=template&id=6d06f3af&scoped=true"
import script from "./Tags.vue?vue&type=script&lang=js"
export * from "./Tags.vue?vue&type=script&lang=js"
import style0 from "./Tags.vue?vue&type=style&index=0&id=6d06f3af&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d06f3af",
  null
  
)

export default component.exports