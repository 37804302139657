<template>
    <div class="purchase-submissions">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-lg-9 col-xl-7 mx-auto">

                    <!-- Brand -->
                    <div class="row align-items-center d-none d-md-flex">
                        <div class="col-md-6">
                            <router-link to="/" class="logo">
                                <img src="@/assets/logo.svg" alt="FormWise">
                            </router-link>
                        </div>
                        <div class="col-md-6 mt-4 mt-md-0 text-md-right">
                            <h6 class="m-0">{{ user.email }}</h6>
                        </div>
                    </div>

                    <!-- Purchase Form -->
                    <div class="purchase-form mt-4" v-if="!purchased">
                        <div class="purchase-summary">
                            <h3>+{{ parseInt(totalSubmissionsSelected).toLocaleString() }} submissions</h3>
                            <p>Use across all your projects and forms with no monthly fees.</p>
                        </div>
                        <ul class="purchase-features">
                            <li>Up to {{ (totalSubmissionsSelected * 4).toLocaleString() }} instant email notifications</li>
                            <li>Up to 4 email recipients on each form</li>
                            <li>One time purchase - no monthly fees</li>
                            <li>Enhanced spam protection</li>
                        </ul>
                        <div class="row">
                            <div class="col-xl-6">
                                <select v-model="totalSubmissionsSelected" class="form-control">
                                    <option :value="option.submissions" v-for="(option, key) in packages" :key="key">{{ option.submissions.toLocaleString() }} submissions - ${{ option.price }}</option>
                                </select>
                            </div>
                            <div class="col-xl-6 mt-2 mt-xl-0">
                                <button class="btn btn-primary" @click.prevent="openPaymentModal">
                                    <span>Process Payment</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- Purchase Completed -->
                    <div class="purchase-completed mt-4" v-if="purchased">
                        <img src="@/assets/icons/check.svg" alt="">
                        <h3 class="mt-4">+{{ parseInt(totalSubmissionsSelected).toLocaleString() }} submissions</h3>
                        <h4 class="mt-4">Thank you for your purchase!</h4>
                        <p>You have successfully completed your purchase.<br>The purchased submissions will be added to your account very shortly!</p>
                        <router-link to="/transactions" class="btn btn-primary mt-4">View Transactions</router-link>
                        <button class="btn btn-link mt-4 ml-4" @click.prevent="purchased = false; totalSubmissionsSelected = 1500;">Buy Again</button>
                    </div>

                    <!-- Copyright -->
                    <p class="small mt-4 text-center" style="opacity: 0.5;">Payments processed by Paddle</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {initializePaddle} from "@paddle/paddle-js";

export default {
    name: "PurchaseSubmissions",
    metaInfo: {
        title: "Purchase Submissions",
    },
    data() {
        return {
            purchased: false,
            packages: [
                {
                    submissions: 500,
                    price: 15,
                    priceId: process.env.VUE_APP_PRICE_15,
                },
                {
                    submissions: 1500,
                    price: 30,
                    priceId: process.env.VUE_APP_PRICE_30,
                },
                {
                    submissions: 2500,
                    price: 50,
                    priceId: process.env.VUE_APP_PRICE_50,
                },
                {
                    submissions: 5000,
                    price: 100,
                    priceId: process.env.VUE_APP_PRICE_100,
                },
            ],
            totalSubmissionsSelected: 1500,
        }
    },
    created() {
        this.initializePaddle();
    },
    methods: {
        getPackageBySubmissions(submissions) {
            let _package = null;
            this.packages.forEach(_pk => {
                if(_pk.submissions === submissions) {
                    _package = _pk;
                }
            });
            return _package;
        },
        openPaymentModal() {
            let selectedPackage = this.getPackageBySubmissions(this.totalSubmissionsSelected);
            if(selectedPackage === null) {
                alert("Please select valid package");
                return;
            }
            this.buySubmissions(selectedPackage);
        },
        async initializePaddle() {
            let app = this;
            try {
                this.paddle = await initializePaddle({
                    environment: process.env.VUE_APP_PADDLE_ENVIRONMENT,
                    token: process.env.VUE_APP_PADDLE_TOKEN,
                    pwCustomer: {
                        id: app.customerPaddleId,
                        email: app.user.email,
                    },
                    eventCallback: function(data) {
                        if (data.name === "checkout.completed") {
                            app.finishPurchase();
                        }
                    },
                });
            } catch (error) {
                console.error('Error initializing Paddle:', error);
            }
        },
        buySubmissions(pricePackage) {
            if(this.paddle) {
                this.paddle.Checkout.open({
                    items: [{ priceId: pricePackage.priceId }],
                    customer: {
                        email: this.user.email,
                    },
                });
            }
        },
        finishPurchase() {
            let purchasedSubmissions = parseInt(this.limits.purchasedSubmissions) + parseInt(this.totalSubmissionsSelected);
            let availableSubmissions = parseInt(this.limits.availableSubmissions) + parseInt(this.totalSubmissionsSelected);
            this.$store.commit("updateLimits", {
                purchasedSubmissions: purchasedSubmissions,
                availableSubmissions: availableSubmissions,
            });
            this.purchased = true;
        },
    },
    computed: {
        user() {
            return this.$store.getters.user;
        },
        customerPaddleId() {
            return this.$store.getters.user.customerId;
        },
        limits() {
            return this.$store.getters.limits;
        },
    },
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.purchase-submissions {
    min-height: 100svh;
    padding: 2.5rem 0;
    background-color: $background-grey;

    @include smartphone {
        padding: 0;
        min-height: inherit;
        background: none;
    }

    .logo {
        display: block;
        text-decoration: none;
        margin: 0;

        img {
            display: inline-block;
            vertical-align: middle;
			height: 2rem;
        }
    }

    .purchase-completed {
        display: block;
        padding: 3.5rem;
        background: $white;
        border-radius: $box-border-radius;
        box-shadow: rgba($dark, 0.1) 0 1px 2px;
        position: relative;
        text-decoration: none;
        text-align: center;

        @include smartphone {
            padding: 1.5rem;
        }

        img {
            width: 6rem;
            height: 6rem;
        }

        h3 {
            font-size: 2rem;
            font-weight: 600;
            color: $primary;
        }

        h4 {
            color: $dark;
            font-weight: 600;
            font-size: 1.5rem;
        }
    }

    .purchase-form {
        display: block;
        padding: 3.5rem;
        background: $white;
        border-radius: $box-border-radius;
        box-shadow: rgba($dark, 0.1) 0 1px 2px;
        position: relative;
        text-decoration: none;

        @include smartphone {
            padding: 1.5rem;
        }

        .purchase-summary {
            h3 {
                font-size: 2rem;
                font-weight: 600;
                color: $dark;
            }
        }

        .purchase-features {
            margin: 1.5rem 0;
            padding: 1.5rem 0;
            border-top: 1px solid $border-grey;
            border-bottom: 1px solid $border-grey;
            list-style: none;
            display: block;

            li {
                display: block;
                font-size: 1rem;
                color: rgba($dark, 0.75);
                position: relative;
                padding-left: 2rem;

                & + li {
                    margin-top: 0.75rem;
                }

                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    width: 1.5rem;
                    height: 1.5rem;
                    background-color: rgba($dark, 0.1);
                    content: ' ';
                    border-radius: 50%;
                    margin: auto;
                }

                &:after {
                    position: absolute;
                    top: 0;
                    bottom: 0.1rem;
                    left: 0.425rem;
                    width: 0.65rem;
                    height: 0.35rem;
                    border-left: 2px solid $dark;
                    border-bottom: 2px solid $dark;
                    transform-origin: center;
                    transform: rotate(-45deg);
                    content: ' ';
                    z-index: 1;
                    margin: auto;
                }
            }
        }

        .form-control {
            padding: 15px 10px;
            height: auto;
            border-radius: $box-border-radius;
            border: 1px solid $border-darker-grey;

            &:focus {
                box-shadow: none;
                border-color: $primary;
            }
        }

        .btn.btn-primary {
            display: flex;
            width: 100%;
            height: 100%;

            strong {
                margin-right: 1rem;
                padding-right: 1rem;
                border-right: 1px solid rgba($white, 0.1);
            }
        }
    }
}
</style>