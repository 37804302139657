<template>
    <div class="form-field-column-field">

        <div :class="{'field-type type-input': true, 'selected': currentElement}" v-if="field.element === 'input' && (field.type !== 'checkbox' && field.type !== 'radio')" @click.stop="edit">
            <label class="form-label" :for="field.id + '-' + field._id">{{ field.label }} <span class="field-required" v-if="field.required">*</span></label>
            <input
                :type="field.type"
                :name="field.name + '-' + field._id"
                :id="field.id + '-' + field._id"
                :required="field.required"
                :placeholder="field.placeholder"
                class="form-control">
        </div>

        <div :class="{'field-type type-textarea': true, 'selected': currentElement}" v-if="field.element === 'textarea'" @click.stop="edit">
            <label class="form-label" :for="field.id + '-' + field._id">{{ field.label }} <span class="field-required" v-if="field.required">*</span></label>
            <textarea
                :name="field.name + '-' + field._id"
                :id="field.id + '-' + field._id"
                :required="field.required"
                :placeholder="field.placeholder"
                class="form-control"></textarea>
        </div>

        <div :class="{'field-type type-select': true, 'selected': currentElement}" v-if="field.element === 'select'" @click.stop="edit">
            <label class="form-label" :for="field.id + '-' + field._id">{{ field.label }} <span class="field-required" v-if="field.required">*</span></label>
            <select
                :name="field.name + '-' + field._id"
                :id="field.id + '-' + field._id"
                :required="field.required"
                class="form-control">
                <option :value="option.value" v-for="(option, optionKey) in field.options" :key="optionKey">{{ option.text }}</option>
            </select>
        </div>

        <div :class="{'field-type type-checkbox': true, 'selected': currentElement}" v-if="field.element === 'input' && field.type === 'checkbox'" @click.stop="edit">
            <label class="form-label" :for="field.id + '-' + field._id">{{ field.label }} <span class="field-required" v-if="field.required">*</span></label>
            <div class="field-checkboxes-wrapper">
                <div class="form-check" v-for="(option, checkboxId) in field.options" :key="checkboxId">
                    <input type="checkbox" class="form-check-input" :name="field.name + '-' + field._id" :value="option.value" :id="field.id + '-' + field._id + '-' + checkboxId">
                    <label :for="field.id + '-' + field._id + '-' + checkboxId" class="form-check-label">{{ option.text }}</label>
                </div>
            </div>
        </div>

        <div :class="{'field-type type-radio': true, 'selected': currentElement}" v-if="field.element === 'input' && field.type === 'radio'" @click.stop="edit">
            <label class="form-label" :for="'formwise-field-' + field.id">{{ field.label }} <span class="field-required" v-if="field.required">*</span></label>
            <div class="field-radio-wrapper">
                <div class="form-check" v-for="(option, radioId) in field.options" :key="radioId">
                    <input type="radio" class="form-check-input" :name="field.name + '-' + field._id" :value="option.value" :id="field.id + '-' + field._id + '-' + radioId">
                    <label :for="field.id + '-' + field._id + '-' + radioId" class="form-check-label">{{ option.text }}</label>
                </div>
            </div>
        </div>

        <div :class="{'field-type type-heading': true, 'selected': currentElement}" v-if="field.element === 'heading'" @click.stop="edit">
            <h1 v-if="field.type === 'h1'">{{ field.text }}</h1>
            <h2 v-if="field.type === 'h2'">{{ field.text }}</h2>
            <h3 v-if="field.type === 'h3'">{{ field.text }}</h3>
            <h4 v-if="field.type === 'h4'">{{ field.text }}</h4>
            <h5 v-if="field.type === 'h5'">{{ field.text }}</h5>
            <h6 v-if="field.type === 'h6'">{{ field.text }}</h6>
        </div>

        <div :class="{'field-type type-paragraph': true, 'selected': currentElement}" v-if="field.element === 'paragraph'" @click.stop="edit">
            <p>{{ field.text }}</p>
        </div>

        <div :class="{'field-type type-hr': true, 'selected': currentElement}" v-if="field.element === 'hr'" @click.stop="edit">
            <hr>
        </div>

        <div :class="{'field-type type-spacing': true, 'selected': currentElement}" v-if="field.element === 'spacing'" @click.stop="edit">
            <div class="form-spacer" :style="{'height': field.size + 'px'}">
                <span>Spacer {{ field.size }}px</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormElement",
    props: [
        'field',
        'rowId',
        'columnId',
        'fieldId',
        'currentElement',
        'editField',
    ],
    methods: {
        edit() {
            this.editField(this.rowId, this.columnId, this.fieldId);
        },
    },
}
</script>

<style scoped lang="scss">
@import "../../../scss/variables";

.form-field-column-field {
    .field-type {
        cursor: pointer;

        * {
            @extend .disable-clicks;
            @extend .disable-selection;
        }

        &.selected {
            .form-label {
                color: $primary;
            }

            .form-control {
                border-color: $primary;
                box-shadow: rgba($primary, 0.25) 0 0.125rem 0.5rem;
            }
        }

        &:hover {
            .form-label {
                color: $primary;
            }

            .form-control {
                border-color: $primary;
            }
        }

        &.type-heading {
            h1, h2, h3, h4, h5, h6, p {
                margin: 0;
            }

            h1 {
                font-size: 3rem;
                font-weight: 600;
                color: $dark;
            }

            h2 {
                font-size: 2.25rem;
                font-weight: 600;
                color: $dark;
            }

            h3 {
                font-size: 1.75rem;
                font-weight: 600;
                color: $dark;
            }

            h4 {
                font-size: 1.5rem;
                font-weight: 600;
                color: $dark;
            }

            h5 {
                font-size: 1.25rem;
                font-weight: 600;
                color: $dark;
            }

            h6 {
                font-size: 1rem;
                font-weight: 600;
                color: $dark;
            }
        }

        &.type-paragraph {
            p {
                margin: 0;
                color: $dark;
            }
        }

        &.type-textarea {
            .form-control {
                min-height: 8rem;
                height: auto;
            }
        }

        &.type-hr {
            hr {
                margin: 0.75rem 0;
                width: 100%;
                height: 1px;
                border: none;
                background: $border-grey;
                opacity: 1;
            }
        }

        &.type-spacing {
            .form-spacer {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                border-radius: $box-border-radius;
                pointer-events: all !important;
                @extend .animated;

                &:hover {
                    background: rgba($primary, 0.1);

                    span {
                        opacity: 1;
                    }
                }

                span {
                    display: block;
                    font-size: 0.8rem;
                    color: $primary;
                    opacity: 0;
                    @extend .animated;
                }
            }
        }
    }

    .form-label {
        font-weight: 500;
        font-size: 0.9rem;
        color: $dark;
        margin-bottom: 0.25rem;

        .field-required {
            color: $danger;
        }
    }

    .form-control {
        border-radius: 5px;
        border: 1px solid $border-dark-grey;
        padding: 0.75rem;
        height: calc(3rem + 2px);

        &:focus {
            box-shadow: none;
            border-color: $primary;
        }
    }
}
</style>