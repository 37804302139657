import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueConfirmDialog from 'vue-confirm-dialog'
import Clipboard from 'v-clipboard'
import VueGtag from "vue-gtag"
import VueMeta from "vue-meta";

Vue.config.productionTip = false

Vue.use(VueConfirmDialog)
Vue.use(Clipboard)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.use(VueGtag, {
    config: {
        id: process.env.VUE_APP_GTAG,
    },
});
Vue.use(VueMeta);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
