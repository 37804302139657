<template>
	<div :class="{'page-wrapper form-details-page': true, 'loading': !loaded}">

        <!-- Form Header -->
		<div class="form-header">
            <div class="row align-items-center">
                <div class="col-xl-4 col-lg-4">
                    <div class="form-details">
                        <router-link :to="formLink" class="form-color" :style="{'background': formColor }"></router-link>
                        <router-link :to="formLink" class="form-name">{{ formName }}</router-link>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-8 mt-2 mt-lg-0">
                    <ul class="form-links justify-content-lg-end" v-if="loaded && type > 0">
                        <li>
                            <router-link :to="formLink" exact>Submissions</router-link>
                        </li>
                        <li>
                            <router-link :to="formLink + '/analytics'">Analytics</router-link>
                        </li>
                        <li>
                            <router-link :to="formLink + '/settings'">Settings</router-link>
                        </li>
                        <li v-if="type === 1">
                            <router-link :to="formLink + '/integration'" class="btn btn-primary ml-md-2">
                                <img src="@/assets/icons/code.svg">
                                <span>Form Integration</span>
                            </router-link>
                        </li>
                        <li v-if="type === 2">
                            <router-link :to="formLink + '/builder'" class="btn btn-primary ml-md-2">
                                <img src="@/assets/icons/form-builder.svg">
                                <span>Form Builder</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
		</div>

        <!-- Form Type Selection -->
        <FormType
            v-if="loaded && type === 0"
            :formId="secret"
            :projectId="projectId"
            :formLink="formLink"
            :updateType="updateType">
        </FormType>

        <!-- Form Screens -->
		<div class="form-body">
            <router-view
                :projectName="name"
                :projectColor="color"
                :projectId="projectId"
                :formLink="formLink"
                :formName="formName"
                :secret="secret"
                :type="type"
                v-if="loaded && type > 0">
            </router-view>
		</div>

	</div>
</template>

<script>
import repository from "@/repository/repository";
import FormType from "@/views/Forms/FormType.vue";

export default {
    name: "FormDetails",
    components: {FormType},
    metaInfo() {
        return {
            title: (this.name === null ? "" : this.name + " / ") + "Forms",
        }
    },
    data() {
        return {
            id: null,
            name: null,
            secret: null,
            type: null,
            color: {
                background: null,
                isDarkText: null,
            },
            loaded: false,
        }
    },
    mounted() {
        // Load form with the provided form hash id
        this.loadForm(this.$route.params.id);

        // Re-load the statistics when needed
        this.reloadCurrentFormHandler = () => {
            this.loadForm(this.$route.params.id, false);
        };

        this.$root.$on('reloadCurrentForm', this.reloadCurrentFormHandler);
    },
    beforeDestroy() {
        // Remove the event listener when the component is destroyed
        this.$root.$off('reloadCurrentForm', this.reloadCurrentFormHandler);
    },
    methods: {
        loadForm(id, loadingEffect = true) {
            console.log("TRYING TO LOAD HERE", this.projectId);
            if(!id) return null;
            this.id = id;
            if(!this.projectId) return null;
            if(loadingEffect) {
                this.loaded = false;
            }
            repository.get("/projects/" + this.projectId + "/forms/" + id)
                .then(response => {
                    this.name = response.data.name;
                    this.secret = response.data.secret;
                    this.type = response.data.type;
                    this.color.background = response.data.color.color;
                    this.color.isDarkText = response.data.color.text === "dark";
                    this.loaded = true;

                    if(this.type === 0 && this.$route.path !== "/forms/" + id) {
                        this.$router.replace("/forms/" + id);
                    }
                    console.log("SUCCESS HERE");
                })
                .catch(() => {
                    console.log("FAILED HERE");
                    if (this.$route.path !== "/") this.$router.replace("/");
                })
        },
        updateType(type) {
            this.type = type;
        },
    },
    computed: {
        projectId() {
            return this.$store.getters.currentProject.hashId;
        },
        formLink() {
            if(!this.loaded) return "";
            return "/forms/" + this.id;
        },
        formName() {
            if(!this.loaded) return "N/A";
            return this.name;
        },
        formColor() {
            if(!this.loaded) return null;
            return this.color.background;
        },
    },
    watch: {
        "$route.params.id"(val) {
            this.loadForm(val);
        },
        projectId: function (newValue, oldValue) {
            if(newValue !== null && oldValue === null) {
                this.loadForm(this.id);
            } else {
                if (this.$route.path !== "/") this.$router.replace("/");
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.form-details-page {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    @include smartphone {
        padding: 0 0.45rem;
    }

    .form-header {
        background: $white;
        padding: 1rem 1.5rem;
        border-bottom: 1px solid $border-grey;
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 98;

		@include smartphone {
			position: relative;
			top: 0;
			background: none;
			box-shadow: none;
            padding: 0.5rem 0.75rem;
            border-top: 1px solid $border-grey;
			z-index: 2;
		}

        .form-details {
            display: flex;
            align-items: center;
            width: 100%;

            .form-color {
                width: 2rem;
                height: 2rem;
                border-radius: $box-border-radius;
                display: block;
                margin-right: 0.5rem;
                flex-shrink: 0;
            }

            .form-name {
                display: block;
                margin: 0;
                color: $dark;
                font-weight: 600;
                font-size: 1.25rem;
                line-height: 43px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

				@include tablet {
					font-size: 1.15rem;
                    line-height: 1.2;
				}

				@include smartphone {
					font-size: 1.15rem;
                    line-height: 1.2;
				}
            }
        }

        .form-links {
            display: flex;
            align-items: center;
            list-style: none;
            margin: 0;
            padding: 0;

			@include smartphone {
				flex-wrap: nowrap;
				overflow-y: hidden;
				width: calc(100% + 1.5rem);
				margin: 0 -0.75rem;
				padding: 0.75rem 0.75rem 0 0.75rem;
			}

            li {
                padding: 0 0 0 5px;

                @include small-desktop {
                    padding: 0 1px;
                }

                @include tablet {
                    padding: 0 1px;
                }

				@include smartphone {
					padding: 0;
					margin-right: 5px;
				}

                a:not(.btn) {
                    color: $dark;
                    font-size: 1rem;
                    font-weight: 500;
                    padding: 7.5px 10px;
                    border-radius: $box-border-radius;
                    position: relative;
                    user-select: none;
                    display: block;
                    text-decoration: none;

                    @extend .animated;

                    @include tablet {
                        padding: 5px 10px;
                    }

					@include smartphone {
						white-space: nowrap;

					}

                    &:hover {
                        background: $hover-grey;
                        color: $primary;
                    }

                    &:active {
                        background: $active-grey;
                        color: $primary;
                    }

                    &.router-link-exact-active,
                    &.router-link-active {
                        background: $hover-grey;
                        color: $primary;

						@include smartphone {
							background: rgba($primary, 0.1);
						}
                    }

                    &.disabled {
                        background: $hover-grey;
                        cursor: default;
                        pointer-events: none;

                        .form-link-content {
                            opacity: 0;
                        }

                        .spinner-border {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                            width: 1.5rem;
                            height: 1.5rem;
                            border-width: 2px;
                            opacity: 0.25;
                        }
                    }
                }

                a.btn.btn-primary {
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                    color: $white;

                    img {
                        width: 1.5rem;
                        height: 1.5rem;
                        margin-right: 0.5rem;
                        margin-left: -0.5rem;
                        filter: brightness(0) invert(1);
                    }
                }
            }
        }
    }

    .form-body {
        padding: 0;
        flex-grow: 1;
    }

    &.loading {
        opacity: 0;

        .form-header {
            .form-details {

                .form-color {
                    background: $hover-grey !important;
                    cursor: default;
                    pointer-events: none;
                }

                .form-name {
                    background: $hover-grey;
                    cursor: default;
                    pointer-events: none;
                    color: transparent;
                    border-radius: $box-border-radius;
                    width: 100%;
                    overflow: hidden;
                    position: relative;

                    &:before {
                        position: absolute;
                        top: 0;
                        left: -80%;
                        width: 80%;
                        height: 100%;
                        background: $active-grey;
                        content: ' ';
                        -webkit-animation: LOADING-EFFECT 0.65s linear infinite; /* Safari 4+ */
                        -moz-animation: LOADING-EFFECT 0.65s linear infinite; /* Fx 5+ */
                        -o-animation: LOADING-EFFECT 0.65s linear infinite; /* Opera 12+ */
                        animation: LOADING-EFFECT 0.65s linear infinite; /* IE 10+, Fx 29+ */

                        @extend .animated;
                    }
                }
            }

            .form-links {
                li {
                    a:not(.btn) {
                        background: $hover-grey;
                        cursor: default;
                        pointer-events: none;
                        color: transparent;
                    }

                    a.btn {
                        background: $hover-grey;
                        cursor: default;
                        pointer-events: none;
                        color: transparent;
                        border-color: $hover-grey;

                        img {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
}
</style>