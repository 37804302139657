<template>
	<div class="form-introduction">
        <div class="row">
            <div class="col-md-8 mx-auto text-center">
                <h2>Your form has been created</h2>
                <p>Complete the process by building your form using our easy form builder, or continue with your own code</p>
            </div>
        </div>
        <div class="row justify-content-center mt-4">
            <div class="col-xl-4">
                <div :class="{'form-option': true, 'selected': selected === 2, 'not-selected': selected === 1}" @click.prevent="updateFormType(2)">
                    <img src="@/assets/icons/form-builder.svg" alt="">
                    <h4>Form Builder <span>New</span></h4>
                    <p>Build and customize your forms quickly and intuitively without any coding knowledge required</p>
                </div>
            </div>
            <div class="col-xl-4 mt-4 mt-xl-0">
                <div :class="{'form-option v2': true, 'selected': selected === 1, 'not-selected': selected === 2}" @click.prevent="updateFormType(1)">
                    <img src="@/assets/icons/code.svg" alt="">
                    <h4>Custom Code</h4>
                    <p>Start accepting submissions from your custom coded form instantly. <strong>You'll need to update your form endpoint URL.</strong></p>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import repository from "@/repository/repository";

export default {
    name: "FormType",
    props: ['formId', 'projectId', 'formLink', 'updateType'],
    data() {
        return {
            selected: null,
        }
    },
    methods: {
        updateFormType(type) {
            // Verify form type and required fields
            if((type !== 1 && type !== 2) || !this.projectId || !this.formId) {
                return;
            }

            // Select effect
            this.selected = type;

            // Save updates in database
            repository.put("/projects/" + this.projectId + "/forms/" + this.formId + "/type", {
                type: type,
            }).then(() => {

                // Update local type variable and redirect to according screen
                this.updateType(type);
                if(type === 1) {
                    this.$router.push(this.formLink + "/integration");
                }
                if(type === 2) {
                    this.$router.push(this.formLink + "/builder");
                }

            }).catch(error => {
                console.log(error);
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.form-introduction {
    background: $white;
    padding: 5rem 1.5rem;
    height: 100%;
    flex-grow: 1;

    h2 {
        font-weight: bold;
        color: $dark;
        font-size: 2.5rem;
    }

    .form-option {
        display: block;
        text-align: center;
        padding: 3rem;
        border-radius: $box-border-radius;
        background: $background-grey;
        height: 100%;
        cursor: pointer;

        @extend .animated;
        @extend .disable-selection;

        &:hover {
            box-shadow: rgba($dark, 0.1) 0 0.25rem 1rem;
            background: $white;
        }

        &:active {
            box-shadow: rgba($dark, 0.15) 0 0.5rem 1.25rem;
            background: $white;
            transform-origin: center;
            transform: scale(0.985);
        }

        &.v2 {
            img {
                filter: grayscale(1);
            }
        }

        &.not-selected {
            opacity: 0.5;
            filter: grayscale(1);
            @extend .disable-clicks;
        }

        &.selected {
            box-shadow: rgba($primary, 0.1) 0 0.15rem 1rem;
            background: $white;
            @extend .disable-clicks;
        }

        img {
            display: block;
            width: 4rem;
            height: 4rem;
            margin: auto;
        }

        h4 {
            font-weight: 700;
            color: $dark;
            margin: 1.5rem 0 1rem 0;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                background: $primary;
                display: inline-block;
                padding: 0.4rem 0.5rem;
                border-radius: $box-border-radius;
                color: $white;
                font-size: 1rem;
                line-height: 1;
                text-transform: uppercase;
                margin-left: 0.5rem;
            }
        }

        p {
            margin: 0;
            font-size: 0.9rem;
            color: $dark;
        }
    }
}
</style>