<template>
    <div class="form-wrapper">

        <!-- Form Title -->
        <h1>New Form</h1>
        <p>Enter your form details below</p>

        <div class="alert alert-danger p-0" v-if="error">{{ error }}</div>

        <form action="#" @submit.prevent="saveForm" :class="{'new-project-form': true}">

            <!-- Loading effect -->
            <div class="loading" v-if="!currentProject.loaded">
                <span class="spinner-border"></span>
            </div>

            <!-- Form Fields -->
            <div class="fields-wrapper" v-if="currentProject.loaded">
                <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" placeholder="Enter your form name" v-model="name">
                </div>
<!--                <div class="form-group">-->
<!--                    <label>Color</label>-->
<!--                    <ColorPicker v-model="color" ref="picker"></ColorPicker>-->
<!--                    <p class="small">Use this color to quickly identify your form in our app.</p>-->
<!--                </div>-->
                <RecipientsField v-model="recipients"/>
<!--                <div class="form-group">-->
<!--                    <label>Google reCAPTCHA v2 Secret Key</label>-->
<!--                    <input type="text" class="form-control" placeholder="Protect yourself from spam" v-model="recaptchaPrivateKey">-->
<!--                </div>-->
                <div class="mt-4">
                    <button type="submit" class="btn btn-primary" style="width: 180px" :disabled="saving || !currentProject.loaded">
                        <span class="spinner-border" v-if="saving || !currentProject.loaded"></span>
                        <span :class="{'opacity-0': saving || !currentProject.loaded}">Create Form</span>
                    </button>
                </div>
            </div>

        </form>
    </div>
</template>

<script>
import ColorPicker from "@/components/widgets/ColorPicker";
import repository from "@/repository/repository";
import RecipientsField from "@/components/widgets/RecipientsField.vue";

export default {
    name: "NewForm",
    components: {RecipientsField},
    data() {
        return {
            name: "",
            recaptchaPrivateKey: null,
            recipients: [],
            color: null,
            error: null,
            saving: false,
        }
    },
    created() {
    },
    methods: {
        saveForm() {
            if(!this.currentProject.loaded) return;
            this.startSaving();
            this.setError(null);
            if (!this.name || this.name.trim().length < 3) {
                this.setError("The form name is required, and must have at least 3 characters.");
                this.endSaving();
                return;
            }
            // If everything passes, save the form
            repository.post("/projects/" + this.currentProject.hashId + "/forms", {
                name: this.name,
                recipients: this.recipients,
                color_id: this.color,
                recaptcha_private: this.recaptchaPrivateKey,
            })
                .then(response => {
                    // Get the new form ID
                    console.log(response);
                    let newFormHashId = response.data.form.hashId;

                    // Refresh the forms
                    this.$root.$emit("closePopups");
                    this.$root.$emit("reloadForms");

                    // Redirect back to home screen, or refresh stats if already there
                    this.$router.push("/forms/" + newFormHashId);

                    // Reset fields
                    this.name = "";
                    // this.recipients = [];
                    this.recaptchaPrivateKey = "";
                    // this.$refs.picker.randomValue();
                    this.endSaving();
                })
                .catch(error => {
                    console.log(error);
                    if (error.response.data.message) {
                        this.setError(error.response.data.message);
                    } else {
                        this.setError("An error happened while saving your form.");
                    }
                    this.endSaving();
                })
        },
        setError(error) {
            this.error = error;
        },
        startSaving() {
            this.saving = true;
        },
        endSaving() {
            this.saving = false;
        },
    },
    computed: {
        currentProject() {
            return this.$store.getters.currentProject;
        },
    },
    watch: {
        '$store.getters.user.email': function (email) {
            this.recipients.push({
                id: -1,
                email: email,
            });
        }
    }
}
</script>

<style lang="scss">
@import "../../scss/variables";

.form-wrapper {

    h1 {
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        color: $dark;
        line-height: 1.2;
        font-size: 1.25rem;
        margin-bottom: 10px;
    }

    p {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        color: $dark;
        font-size: 1rem;
        line-height: 1.4;

        &.small {
            color: $grey-text;
            font-size: 0.8rem;
            margin: 0.25rem 0 0 0;
        }
    }

    .new-project-form {
        position: relative;

        &.is-disabled {
            user-select: none;
        }

        .loading {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: $white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 3;

			@include smartphone {
				background: $white;
			}

            .spinner-border {
                border-color: $border-darker-grey;
                border-right-color: transparent;
            }
        }

        .alert-wrapper {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: rgba($background-grey, 0.85);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 3;

            .alert.alert-info {
                max-width: 90%;
            }
        }

        .form-group {
            position: relative;

            label {
                display: block;
                font-size: 1rem;
                font-weight: 600;
                margin-bottom: 3px;
                color: $dark;
            }

            .form-control {
                padding: 15px 10px;
                height: auto;
                border-radius: 0.75rem;
                border: 1px solid $border-darker-grey;

                &:focus {
                    box-shadow: none;
                    border-color: $primary;
                }
            }
        }
    }
}
</style>