<template>
    <div class="form-actions">
        <!-- Mark all as seen -->
<!--        <a href="#" @click.prevent="markAllSubmissionsAsSeen" :class="{'loading': actions.marking, 'disabled': (loaded && totalSubmissions === 0)}">-->
<!--            <img src="@/assets/icons/submission-completed.svg" alt="Seen">-->
<!--            <span class="form-link-content">Mark as Seen</span>-->
<!--            <span v-show="actions.marking" class="spinner-border"></span>-->
<!--        </a>-->

        <!-- Clear submissions -->
        <a href="#" @click.prevent="deleteSubmissions" :class="{'loading': actions.deletingSubmissions}">
            <img src="@/assets/icons/trash.svg" alt="Users">
            <span class="form-link-content">Delete All Submissions</span>
            <span v-show="actions.deletingSubmissions" class="spinner-border"></span>
        </a>

        <!-- Export submissions -->
        <a href="#" @click.prevent="exportCSV" :class="{'form-export-action': true, 'loading': actions.exporting}">
            <img src="@/assets/icons/download.svg" alt="Download">
            <span class="form-link-content">Export CSV</span>
            <span v-show="actions.exporting" class="spinner-border"></span>
        </a>
    </div>
</template>

<script>
import repository from "@/repository/repository";

export default {
    name: "FormActions",
    props: ['formName'],
    data() {
        return {
            actions: {
                exporting: false,
                marking: false,
                deletingSubmissions: false,
            },
        }
    },
    methods: {
        exportCSV() {
            if (this.actions.exporting) return;
            this.actions.exporting = true;
            repository.get("/projects/" + this.projectId + "/forms/" + this.formId + "/submissions/export")
                .then(response => {
                    const blob = new Blob([response.data], {type: 'text/csv'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = this.formName + " Submissions.csv";
                    link.click();
                    URL.revokeObjectURL(link.href);
                    this.actions.exporting = false;
                })
                .catch(error => {
                    console.log(error);
                    this.actions.exporting = false;
                })
        },
        markAllSubmissionsAsSeen() {
            if (!this.projectId) return;
            if (!this.formId) return;
            if (this.actions.marking) return;
            this.actions.marking = true;
            repository.put("/projects/" + this.projectId + "/forms/" + this.formId + "/submissions")
                .then(() => {
                    this.actions.marking = false;

                    // Send event for reloading form details & submissions
                    this.$root.$emit("reloadFormSubmissions");
                })
                .catch(error => {
                    console.log(error);
                    this.actions.marking = false;
                })
        },
        deleteSubmissions() {
            if (!this.projectId) return;
            if (!this.formId) return;
            if (this.actions.deletingSubmissions) return;

            this.$confirm({
                title: "Delete all submissions?",
                message: "By confirming, all submissions on the current form will be permanently deleted. This action cannot be undone.",
                button: {
                    yes: "Yes, continue",
                    no: "No, cancel"
                },
                callback: confirm => {
                    if (confirm) {
                        this.actions.deletingSubmissions = true;
                        repository.delete("/projects/" + this.projectId + "/forms/" + this.formId + "/submissions")
                            .then(() => {
                                this.actions.deletingSubmissions = false;

                                // Send event for reloading form details & submissions
                                this.$root.$emit("reloadFormSubmissions");
                            })
                            .catch(error => {
                                console.log(error);
                                this.actions.deletingSubmissions = false;
                            })
                    }
                }
            })
        },
    },
    computed: {
        formId() {
            return this.$route.params.id;
        },
        projectId() {
            return this.$store.getters.currentProject.hashId;
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.form-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include tablet {
        justify-content: flex-end;
        flex-direction: row-reverse;
    }

    @include smartphone {
        margin-bottom: 0.75rem;
    }

    a {
        display: flex;
        align-items: center;
        padding: 7.5px 10px;
        margin: -8.5px 0;
        background: $background-grey;
        border-radius: $box-border-radius;
        border: 1px solid transparent;
        position: relative;
        text-decoration: none;
        color: $dark;
        font-size: 0.9rem;
        font-weight: 500;
        @extend .animated;

        @include tablet {
            padding: 15px 15px;
        }

        @include small-desktop {
            padding: 16.5px 15px;
        }

        & + a {
            margin-left: 0.5rem;

            @include tablet {
                margin-left: 0;
                margin-right: 0.5rem;
            }
        }

        img {
            width: 1.25rem;
            height: 1.25rem;
            filter: grayscale(1);
            margin: 0 0.5rem 0 0;
            opacity: 0.25;
            @extend .animated;
        }

        .spinner-border {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 1.5rem;
            height: 1.5rem;
            border-width: 2px;
            opacity: 0.25;
        }

        &:hover {
            background: lighten($primary, 50%);
            border-color: transparent;
            color: $primary;

            img {
                opacity: 1;
                filter: none;
            }
        }

        &:active {
            background: lighten($primary, 45%);
            border-color: transparent;
            transform-origin: center;
            transform: scale(0.9875);
            color: $primary;

            img {
                opacity: 1;
                filter: none;
            }
        }

        &.disabled {
            @extend .disable-clicks;
            opacity: 0.5;
            filter: grayscale(1);
        }

        &.loading {
            @extend .disable-clicks;

            img, .form-link-content {
                opacity: 0;
            }
        }

        &.form-export-action {
            background: none;
            color: $primary;
            border: 1px solid $primary;

            &:hover {
                background: $primary;
                border-color: $primary;
                color: $white;

                img {
                    filter: brightness(0) invert(1);
                }
            }

            img {
                filter: none;
                opacity: 1;
            }

            &.loading {
                background: $primary;

                img {
                    opacity: 0;
                }

                .spinner-border {
                    z-index: 2;
                    border-color: $white;
                    border-right-color: transparent;
                }
            }
        }
    }
}
</style>