<template>
    <div class="login-page">
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-xl-6 mx-auto">
                    <div class="login-wrapper">

                        <!-- Brand -->
                        <a href="/" class="logo">
                            <img src="@/assets/logo.svg" alt="FormWise">
                        </a>

                        <!-- Login form -->
                        <LoginForm :token="token"></LoginForm>

                    </div>

                    <p class="small mt-4" style="opacity: 0.25;">FormWise.io &copy; 2024 | Version 1.5.0</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoginForm from "@/components/forms/LoginForm";

export default {
    name: "Login",
    metaInfo: {
        title: "Login",
    },
    props: ['token'],
    components: {LoginForm},
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.login-page {
    min-height: 100svh;
    padding: 2.5rem 0;

    @include smartphone {
        align-items: flex-start;
        justify-content: flex-start;
        padding: 25px 0;
        background: $white;
        box-shadow: none;
		min-height: auto;
    }

    .additional-links {
        padding-top: 15px;
        color: $dark;

        a {
            color: $primary;
            font-weight: 600;
        }
    }

    .logo {
        display: block;
        text-decoration: none;
        margin-bottom: 45px;

        @include smartphone {
            margin-bottom: 0;
        }

        img {
            display: inline-block;
            vertical-align: middle;
			height: 2rem;
        }
    }
}
</style>