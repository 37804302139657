<template>
	<div id="app">

		<!-- Body -->
        <div :class="{'page-global-wrapper': true, 'signed-out': !signedIn}">
            <div class="page-global-sidebar">
                <!-- Header -->
                <AppHeader v-if="signedIn"></AppHeader>
            </div>
            <div class="page-global-content">
                <router-view/>
            </div>
        </div>

		<!-- Confirm dialog -->
		<vue-confirm-dialog class="confirm-dialog"></vue-confirm-dialog>

        <!-- Modals -->
        <div class="modals-wrapper" v-if="signedIn">

            <!-- New Project -->
            <Popup :class="{'active': modal === 'newProject'}">
                <NewProject/>
            </Popup>

			<!-- Edit Project -->
			<Popup :class="{'active': modal === 'editProject'}">
				<EditProject/>
			</Popup>

            <!-- New Form -->
            <Popup :class="{'active': modal === 'newForm'}">
                <NewForm/>
            </Popup>

        </div>

        <!-- Realtime Notifications -->
        <RealtimeNotifications></RealtimeNotifications>

	</div>
</template>

<script>
import AppHeader from "@/components/header/Header";
import Popup from "@/components/widgets/Popup";
import NewProject from "@/components/forms/NewProject";
import EditProject from "@/components/forms/EditProject";
import NewForm from "@/components/forms/NewForm";
import RealtimeNotifications from "@/components/widgets/RealtimeNotifications.vue";

export default {
    components: {RealtimeNotifications, NewForm, EditProject, NewProject, Popup, AppHeader, },
    metaInfo: {
        title: "Dashboard",
        titleTemplate: "%s / FormWise",
    },
    data: function () {
        return {
            modal: null,
        }
    },
    created: function() {
        this.$root.$on('closePopups', () => {
            this.modal = null;
            document.documentElement.style.overflow = "auto";
        });
        this.$root.$on('openNewProjectPopup', () => {
            history.pushState({page: 1}, "", "/projects/new");
            this.modal = 'newProject';
            document.documentElement.style.overflow = "hidden";
        });
		this.$root.$on('openEditProjectPopup', (project) => {
			history.pushState({page: 1}, "", "/projects");
			this.$root.$emit('editProjectData', project);
			this.modal = 'editProject';
			document.documentElement.style.overflow = "hidden";
		});
        this.$root.$on('openNewFormPopup', () => {
            history.pushState({page: 1}, "", "/forms/new");
            this.modal = 'newForm';
            document.documentElement.style.overflow = "hidden";
        });
        let app = this;
        window.addEventListener('popstate', function () {
            app.modal = null;
            document.documentElement.style.overflow = "auto";
        });
    },
    computed: {
        signedIn() {
            return this.$store.getters.token !== null;
        }
    },
    watch: {
        '$route': function() {
            this.modal = null;
        },
    },
}
</script>

<style lang="scss">
@import 'node_modules/bootstrap/scss/bootstrap';
@import "src/scss/variables";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

html, body {
	background-color: $white;
	font-family: 'Inter', sans-serif;
	font-size: 14px;

	@include smartphone {
		background: $white;
	}
}

body {
	@include smartphone {
		padding-bottom: 54px;
	}
}

.container-fluid {
	padding-left: 15px !important;
	padding-right: 15px !important;

	@include small-desktop {
		& {
			padding-left: 15px !important;
			padding-right: 15px !important;
		}
	}

	@include tablet {
		& {
			padding-left: 15px !important;
			padding-right: 15px !important;
		}
	}

	@include smartphone {
		& {
			padding-left: 15px !important;
			padding-right: 15px !important;
		}
	}
}

.alert {
	border: none;

	&.alert-danger {
		color: $danger;
		background: transparent;
		padding: 10px 0;
		font-weight: 600;
		font-size: 1rem;
	}

	&.alert-info {
		background: #fff;
		color: $dark;
		padding: 20px;
		box-shadow: $box-shadow-color 0 2px 5px;
		margin: 25px 0;
		border-radius: $box-border-radius;

		strong {
			font-weight: 600;
			display: block;
            font-size: 1.1rem;
		}

		p {
			margin: 5px 0 0 0;
		}

		.btn {
			margin-top: 10px;
		}
	}
}

.confirm-dialog {
	.vc-container {
		border-radius: 6px;
		border: none;
		box-shadow: $box-shadow-color 0 2px 5px;
		min-width: 360px;

		@include smartphone {
			min-width: 300px;
		}

		.vc-text-grid {
			padding: 25px;

			@include tablet {
				padding: 20px;
			}

			.vc-title {
				font-family: 'Inter', sans-serif;
				font-weight: 700;
				color: $dark;
				line-height: 1.2;
				font-size: 1.15rem;
				margin-bottom: 10px;
			}

			.vc-text {
				font-family: 'Inter', sans-serif;
				font-weight: 400;
				color: $dark;
				font-size: 1rem;
				line-height: 1.4;
			}
		}

		.vc-btn-grid {
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;
		}

		.vc-btn {
			font-weight: 600;
			font-size: 1rem;
			color: $dark;
			border-bottom-right-radius: 6px;

			@extend .animated;

			@include tablet {
				min-height: 40px;
			}

			&.left {
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 0;
			}

			&:hover {
				background: $hover-grey;
			}

			&:active,
			&:focus {
				background: $active-grey;
				outline: none;
			}
		}
	}
}

.page-global-wrapper {
    display: flex;
    min-height: 100svh;

    @include smartphone {
        display: block;
        min-height: inherit;
    }

    .page-global-sidebar {
        background: $white;
        width: 22rem;
        height: 100svh;
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        position: sticky;
        top: 0;
        border-right: 1px solid $border-grey;

        @include tablet {
            width: 20rem;
        }

        @include smartphone {
            width: 100%;
            height: auto;
            position: relative;
            z-index: 1;
        }
    }

    .page-global-content {
        flex-grow: 1;
        width: calc(100% - 22rem);

        @include tablet {
            width: calc(100% - 20rem);
        }

        @include smartphone {
            position: relative;
            z-index: 0;
            width: 100%;
        }
    }

    &.signed-out {
        .page-global-sidebar {
            display: none;
        }
    }
}
</style>