<template>
    <div class="page-wrapper transactions-page">
        <ExplanationTitle heading="Transactions" explanation=""/>
        <div class="row">
            <div class="col-lg-8">
                <div class="table-wrapper">
                    <table class="table transactions-table">
                        <thead>
                        <tr>
                            <th>Transaction ID</th>
                            <th>Paid</th>
                            <th>Submissions</th>
                            <th>Status</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loaded && transactions.length === 0">
                            <td colspan="5">No transactions found</td>
                        </tr>
                        <tr v-if="!loaded">
                            <td colspan="5">
                                <div class="spinner-border opacity-25"></div>
                            </td>
                        </tr>
                        <tr v-for="(transaction, id) in transactions" :key="id">
                            <td><span class="transaction-id">{{ transaction.transaction_id }}</span></td>
                            <td>${{ transaction.amount.toFixed(2) }}</td>
                            <td>{{ transaction.submissions }}</td>
                            <td><span :class="{'transaction-status': true, 'status-cancelled': transaction.status === 'cancelled', 'status-paid': transaction.status === 'paid', 'status-free': transaction.status === 'free'}">{{ transaction.status }}</span></td>
                            <td>{{ transaction.date }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
                <div class="transactions-summary">
                    <h4>Summary</h4>
                    <div class="summary-body" v-if="loaded">
                        <div class="summary-item item-lg">
                            <span>Available Submissions Slots</span>
                            <strong :class="{'text-danger': summary.availableSubmissions <= 25}">
                                {{ summary.availableSubmissions > 0 ? summary.availableSubmissions : 0 }}
                            </strong>
                            <p class="text-danger small mt-2 mb-0" v-if="summary.availableSubmissions <= 25"><i class="font-weight-bold" style="font-style: normal;">Your storage is {{ summary.availableSubmissions > 0 ? 'almost' : '' }} full!</i> Please purchase additional submissions slots to continue receiving notifications!</p>
                        </div>
                        <div class="summary-item">
                            <span>Purchased Submissions Slots</span>
                            <strong>{{ summary.totalSubmissions }}</strong>
                        </div>
                        <div class="summary-item">
                            <span>Used Submissions Slots</span>
                            <strong>{{ summary.totalSubmissions - summary.availableSubmissions }}</strong>
                        </div>
                        <div class="summary-item">
                            <span>Total Spent</span>
                            <strong>${{ totalSpent }}</strong>
                        </div>
                        <router-link to="/purchase-submissions" class="btn btn-primary d-block w-100 mt-4">Purchase Submissions Slots</router-link>
                    </div>
                    <div class="summary-loading" v-if="!loaded">
                        <div class="spinner-border"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ExplanationTitle from "@/components/widgets/ExplanationTitle.vue";
import repository from "@/repository/repository";

export default {
    name: "Transactions",
    components: {ExplanationTitle},
    data() {
        return {
            loaded: false,
            transactions: [],
            summary: {
                availableSubmissions: 0,
                totalSubmissions: 0,
            },
        }
    },
    created() {
        this.loadTransactions();
    },
    methods: {
        loadTransactions() {
            repository.get("/transactions")
                .then(response => {
                    this.transactions = response.data.transactions;
                    this.summary.availableSubmissions = response.data.summary.available_submissions;
                    this.summary.totalSubmissions = response.data.summary.total_submissions;
                    this.loaded = true;
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
    computed: {
        totalSpent() {
            if(!this.loaded || !this.transactions || this.transactions.length === 0) return 0;
            let total = 0;
            this.transactions.forEach(transaction => {
                if(transaction.status === 'paid') {
                    total += transaction.amount;
                }
            });
            return total.toFixed(2);
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.transactions-page {
    background: $background-grey;
}

.transactions-summary {
    padding: 2.5rem;
    position: relative;
    text-decoration: none;
    background: $white;
    border-radius: $box-border-radius;
    box-shadow: rgba($dark, 0.1) 0 1px 2px;

    h4 {
        font-size: 1.25rem;
        color: $dark;
        margin: 0 0 1rem 0;
        font-weight: 600;
    }

    .summary-item {
        display: flex;
        margin-top: 1rem;
        padding-top: 1rem;
        border-top: 1px solid rgba($dark, 0.1);
        flex-direction: column;
        align-items: flex-start;

        span {
            display: block;
        }

        &.item-lg {
            strong {
                display: block;
                font-weight: 600;
                font-size: 2.5rem;
                line-height: 1.2;
                margin-bottom: -0.25rem;
                color: $primary;
            }
        }
    }

    .spinner-border {
        width: 1.5rem;
        height: 1.5rem;
        border-color: $border-dark-grey;
        border-right-color: transparent;
        border-width: 2px;
        margin-top: 0.5rem;
    }
}

.table-wrapper {
    padding: 2.5rem;
    height: 100%;
    background: $white;
    border-radius: $box-border-radius;
    box-shadow: rgba($dark, 0.1) 0 1px 2px;

    @include tablet {
        overflow: scroll;
        white-space: nowrap;
    }

    @include smartphone {
        overflow: scroll;
        white-space: nowrap;
    }
}

.table.transactions-table {
    margin-bottom: 0;

    thead {
        tr {
            th {
                padding: 0 1rem 1.5rem 0;
                color: $dark;
                border-top: none;
                border-bottom: none;
                font-size: 1rem;
                font-weight: 600;
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 1.5rem 1rem 1.5rem 0;
                width: 20%;

                &:last-child {
                    width: 25%;
                }

                .transaction-id {
                    text-transform: capitalize;
                    color: $dark;
                    max-width: 160px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: block;
                }

                .transaction-status {
                    font-size: 0.9rem;
                    font-weight: 600;
                    padding: 0.45rem 0.75rem;
                    border-radius: $box-border-radius;
                    text-transform: capitalize;

                    &.status-paid {
                        background-color: rgba(0, 128, 0, 0.1);
                        color: green;
                    }

                    &.status-cancelled {
                        background-color: rgba($danger, 0.1);
                        color: $danger;
                    }

                    &.status-free {
                        background-color: rgba($primary, 0.1);
                        color: $primary;
                    }
                }

                .spinner-border {
                    width: 1.5rem;
                    height: 1.5rem;
                    border-color: $border-dark-grey;
                    border-right-color: transparent;
                    border-width: 2px;
                    margin-top: 0.5rem;
                }
            }
        }
    }
}
</style>