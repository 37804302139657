<template>
	<div :class="{'project-box': true, 'loading': !loaded, 'detailed': detailed}">
		<div class="project-details">
            <div class="project-name">{{ projectName }}</div>
            <div class="project-website">{{ projectWebsite ? projectWebsite : 'No website' }}</div>
            <div class="project-actions">
                <button type="button" class="btn btn-light" @click.prevent="editProject(project)">
                    <img src="@/assets/icons/edit.svg" alt="">
                </button>
                <button type="button" class="btn btn-light" @click.prevent="deleteProject(project)">
                    <img src="@/assets/icons/trash.svg" alt="">
                </button>
            </div>
		</div>
		<div class="project-statistics">
			<div class="row">
				<div class="col-6">
					<strong>{{ totalProjectForms }}</strong>
					<span>Forms</span>
				</div>
				<div class="col-6">
					<strong>{{ totalProjectSubmissions }}</strong>
					<span>Submissions</span>
				</div>
			</div>
		</div>
		<div class="actions">
			<button
                v-if="loaded"
                type="button"
                :class="{'btn btn-primary': true, 'disabled': currentProject.hashId === project.hashId}"
                @click.prevent="updateDefaultProject(project)"
            >
                <span v-if="currentProject.hashId !== project.hashId">Switch to Project</span>
                <span v-else>Current Project</span>
            </button>
		</div>
	</div>
</template>

<script>
import repository from "@/repository/repository";

export default {
	name: "ProjectBox",
	props: ['project', 'detailed'],
	data() {
		return {
			loaded: this.project !== undefined,
		}
	},
	methods: {
        updateDefaultProject(project) {
            this.$store.commit("updateCurrentProject", project);
            repository.post("/default-project", {
                id: project.hashId
            })
                .then(response => {
                    this.$store.commit("updateCurrentProject", response.data.project);
                });
        },
		editProject(project) {
			this.$root.$emit('openEditProjectPopup', project);
		},
		deleteProject() {
			this.$confirm({
				title: "Delete " + this.project.name + "?",
				message: "Please note that this action will permanently remove the " + this.project.name + " and all linked forms. This action cannot be undone.",
				button: {
					yes: "Yes, continue",
					no: "No, cancel"
				},
				callback: confirm => {
					if (confirm) {
						repository.delete("/projects/" + this.project.hashId)
							.then(() => {
								this.$root.$emit('reloadProjects');
								this.$root.$emit('reloadDefaultProject');
							})
							.catch(error => {
								this.$confirm({
									title: error.response.data.message,
									button: { yes: "Continue" }
								})
							})
					}
				}
			})
		},
	},
	computed: {
		projectName() {
			if (!this.loaded) return "Loading..";
			return this.project.name;
		},
		projectWebsite() {
			if (!this.loaded) return "Loading..";
			return this.project.website;
		},
		totalProjectForms() {
			if (!this.loaded) return 0;
			return this.project.forms_count;
		},
		totalProjectSubmissions() {
			if (!this.loaded) return 0;
			return this.project.submissions_count;
		},
        currentProject() {
            return this.$store.getters.currentProject;
        },
	}
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.project-box {
	display: block;
	background: $background-white;
	border-radius: $box-border-radius;
	padding: 1.5rem;
	box-shadow: $box-shadow-color 0 2px 3px;
	position: relative;
	text-decoration: none;
	color: inherit;
	@extend .animated;

	&:hover {
		box-shadow: $box-shadow-color 0 5px 10px;

        .project-details {
            .project-actions {
                opacity: 1;
            }
        }
	}

	.project-details {
        position: relative;

		.project-name {
			font-weight: 600;
			color: $dark;
			font-size: 1rem;
			display: block;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			margin: 0;
			text-decoration: none;
		}

		.project-website {
			font-size: 0.8rem;
			display: block;
			color: $grey-text;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
		}

        .project-actions {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 2;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background: linear-gradient(to left, rgba($white, 1), rgba($white, 0));
            padding-left: 1rem;
            opacity: 0;
            @extend .animated;

            .btn {
                display: flex;
                align-items: center;
                padding: 7.5px;
                background: $background-grey;
                border-radius: $box-border-radius;
                border: 1px solid transparent;
                position: relative;
                text-decoration: none;
                color: $dark;
                font-size: 0.9rem;
                font-weight: 500;
                box-shadow: none;
                margin-left: 0.25rem;
                @extend .animated;

                @include tablet {
                    padding: 15px 15px;
                }

                @include small-desktop {
                    padding: 16.5px 15px;
                }

                img {
                    width: 1.25rem;
                    height: 1.25rem;
                    filter: grayscale(1);
                    margin: 0;
                    opacity: 0.25;
                    @extend .animated;
                }

                .spinner-border {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 1.5rem;
                    height: 1.5rem;
                    border-width: 2px;
                    opacity: 0.25;
                }

                &:hover {
                    background: lighten($primary, 50%);
                    border-color: transparent;
                    color: $primary;
                    box-shadow: none;

                    img {
                        opacity: 1;
                        filter: none;
                    }
                }

                &:active {
                    background: lighten($primary, 45%);
                    border-color: transparent;
                    transform-origin: center;
                    transform: scale(0.9875);
                    color: $primary;
                    box-shadow: none;

                    img {
                        opacity: 1;
                        filter: none;
                    }
                }

                &.disabled {
                    @extend .disable-clicks;
                    opacity: 0.5;
                    filter: grayscale(1);
                }

                &.loading {
                    @extend .disable-clicks;

                    img, .form-link-content {
                        opacity: 0;
                    }
                }
            }
        }
	}

	.project-statistics {
		padding: 1rem 0;
		margin: 1rem 0;
		border-top: 1px solid $border-grey;
		border-bottom: 1px solid $border-grey;

		strong {
			display: block;
			font-size: 1.5rem;
			font-weight: 600;
			color: $dark;
		}

		span {
			font-size: 0.8rem;
			display: block;
			color: $grey-text;
			white-space: normal;

			@include smartphone {
				max-width: 100%;
			}
		}
	}

	.actions {
		margin-top: 20px;
		display: flex;
		gap: 10px;
        flex-wrap: wrap;

		.btn {
            &.btn-primary {
                width: 100%;
                flex-shrink: 0;
                flex-grow: 1;
                flex-basis: 100%;

                &.disabled {
                    filter: none;
                    background: rgba($primary, 0.05);
                    color: $primary;
                    opacity: 1;
                    border-color: transparent;
                }
            }

			flex: 1;
		}
	}

	&.loading {
		pointer-events: none;
		user-select: none;

		.project-details {
			.project-name {
				color: transparent;
				background: $hover-grey;
				border-radius: $box-border-radius;
				position: relative;
			}

			.project-website {
				color: transparent;
				background: $hover-grey;
				border-radius: $box-border-radius;
				position: relative;
				margin-top: 5px;
			}
		}

		.project-statistics {
			border-top-color: transparent;
			border-bottom-color: transparent;

			strong {
				color: transparent;
				background: $hover-grey;
				border-radius: $box-border-radius;
				position: relative;
			}

			span {
				color: transparent;
				background: $hover-grey;
				border-radius: $box-border-radius;
				position: relative;
				transform: translateY(2px);
			}
		}

		.btn {
			color: transparent;
			background: $hover-grey;
			border-radius: $box-border-radius;
			position: relative;
			border-color: transparent;
		}

	}

	@include smartphone {
		height: auto;
		margin-bottom: 15px;
	}
}
</style>