<template>
	<Dropdown :class="{'nav-item project-item': true, 'loading': !projects.loaded || !currentProject.loaded}">
		<template v-slot:toggle>
			<div class="nav-link">
				<span v-if="projects.loaded && currentProject.loaded">{{ currentProject.name }}</span>
                <span class="spinner-border" v-if="!projects.loaded || !currentProject.loaded"></span>
			</div>
		</template>
		<template v-slot:content>
			<h4>Your projects</h4>
			<div class="spinner-border" v-if="!projects.loaded || !currentProject.loaded"></div>
            <div class="projects-wrapper">
                <div
                    @click.prevent="updateDefaultProject(project)"
                    :class="{'dropdown-link': true, 'selected': project.hashId === currentProject.hashId}"
                    v-for="(project, key) in projects.items"
                    v-bind:key="key">
                    <div class="project-color" :style="{'background': getColor(project)}"></div>
                    <span>{{ project.name }}</span>
                </div>
            </div>
			<div class="btn btn-primary d-block w-100 mt-3" @click.prevent="$root.$emit('openNewProjectPopup')">Create new project</div>
			<router-link to="/projects" class="btn btn-outline-dark d-block w-100 mt-2 mb-2">Manage projects</router-link>
		</template>
	</Dropdown>
</template>

<script>
import Dropdown from "@/components/widgets/Dropdown";
import repository from "@/repository/repository";

export default {
    name: "ProjectsDropdown",
    components: {Dropdown},
    created() {
        let app = this;

        // If after X seconds this is still not loaded, simply load the projects
        setTimeout(function () {
            if (!app.projects.loaded) {
                app.loadProjects();
            }
        }, 2000);
    },
    mounted() {
        // Reload projects and default if needed
        this.$root.$on('reloadProjects', this.loadProjects);
        this.$root.$on('reloadDefaultProject', this.loadDefaultProject);
    },
    beforeDestroy() {
        // Remove the event listeners when the component is destroyed
        this.$root.$off('reloadProjects', this.loadProjects);
        this.$root.$off('reloadDefaultProject', this.loadDefaultProject);
    },
    methods: {
        getColor(project) {
            if (!project.color) return "rgba(0,0,0,0.1)";
            return project.color.color;
        },
        loadProjects() {
            this.$store.commit("loadingProjects");
            repository.get("/projects")
                .then(response => {
                    this.$store.commit("updateProjects", response.data);
                })
                .catch(error => {
                    console.log(error);
                })
        },
        loadDefaultProject() {
          repository.get("/me")
              .then(response => {
                this.$store.commit("updateCurrentProject", response.data.user.default_project);
              })
        },
        updateDefaultProject(project) {
            this.$store.commit("updateCurrentProject", project);
            repository.post("/default-project", {
                id: project.hashId
            })
                .then(response => {
                    this.$store.commit("updateCurrentProject", response.data.project);
                    if (this.$route.path !== "/") this.$router.push("/")
                })
        },
    },
    computed: {
        user() {
            return this.$store.getters.user;
        },
        projects() {
            return this.$store.getters.projects;
        },
        currentProject() {
            return this.$store.getters.currentProject;
        },
    },
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";
.project-item {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @include tablet {
        margin: 0.5rem 0;
    }

    @include smartphone {
        margin: 0 auto 0 1rem;
        flex-grow: 0;
        position: static;
        flex-shrink: 1;
        max-width: 10rem;
    }

    .nav-link {
        font-weight: 600;
        color: $primary;
        width: 100%;
        padding: 1rem 1rem;
        border-radius: 0.75rem;
        background: lighten($primary, 50%);
        font-size: 1.1rem;
        display: flex;
        align-items: center;
        min-height: 3.65rem;

        @include tablet {
            padding: 0.5rem 1rem;
            font-size: 1rem;
            min-height: 2.5rem;
        }

        @include smartphone {
            padding: 0.5rem 1rem;
            font-size: 1rem;
            min-height: 2.5rem;
        }

        &:hover {
            background: lighten($primary, 48%);
        }

        &:after {
            margin-left: auto !important;
            display: block !important;
            z-index: 3;
            flex-shrink: 0;
        }

        span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-right: 1rem;
        }

        .spinner-border {
            width: 20px;
            height: 20px;
            border-color: $dark;
            border-width: 2px;
            opacity: 0.2;
            border-right-color: transparent;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 1;

			@include smartphone {
				border-color: rgba($white, 0.5);
				border-right-color: transparent;
			}
        }
    }

    &.loading {
        .nav-link:after {
            opacity: 0 !important;
        }
    }
}

.dropdown-wrapper {
    .dropdown-menu {
        min-width: 100%;
        max-width: 100%;
        width: 100%;

        .projects-wrapper {
            max-height: 200px;
            overflow-x: hidden;
            padding: 0 20px;
            width: calc(100% + 40px);
            margin: 0 -20px;
        }

        .dropdown-link {
            cursor: pointer;

            .project-color {
                display: inline-block;
                vertical-align: middle;
                width: 16px;
                height: 16px;
                border-radius: $box-border-radius;
            }

            span {
                display: inline-block;
                vertical-align: middle;
                width: calc(100% - 21px);
                padding-left: 5px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            &.selected {
                background: rgba($primary, 0.15);
                color: $primary;

                .project-color {
                    background: $primary !important;
                }
            }
        }
    }
}
</style>